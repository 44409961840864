import axios from "axios";
import { eventList, sortReunions } from "../util";

const myAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "api/",
});

myAxios.interceptors.request.use((config) => {
  const userData = localStorage.getItem("userData");
  if (userData != null && JSON.parse(userData) != null) {
    config.headers.Authorization = `Bearer ${JSON.parse(userData).jwt}`;
  }
  return config;
});

myAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const unAuthorized = 401;
    // Legacy expired jwt, newer jwt not expire
    if (error.response != null && error.response.status === unAuthorized) {
      localStorage.setItem("userData", JSON.stringify(null));

      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
    return Promise.reject(error);
  }
);

export const getEvents = async (
  eventKind,
  userId,
  userEmail,
  setTempReunions
) => {
  // TODO use faster deep,2
  const actualReunions = await myAxios.get(
    `events?populate=deep,3&filters[$or][0][host][id][$eq]=${userId}&filters[$or][1][invitees][userEmail][$eq]=${userEmail}&pagination[limit]=15&sort=id:desc`
  );

  const meetList = sortReunions(eventKind, actualReunions, userId, userEmail);
  if (setTempReunions != null) {
    setTempReunions(meetList);
  }
  return meetList;
};

export const getLinkedEvent = async (
  eventLink,
  myEmail,
  myFullName,
  navigateVal
) => {
  const eventResponse = await myAxios.get(
    `events?populate=deep,2&filters[urlLink][$eq]=${eventLink}`
  );
  const dataInfo = eventResponse.data.data[0];
  const isInvitee =
    dataInfo.attributes.host.data.attributes.email === myEmail ||
    dataInfo.attributes.invitees.data.some(
      (x) => x.attributes.email === myEmail
    );
  const navValue = `/${eventList}${dataInfo.id}`;
  if (isInvitee) {
    navigateVal(navValue);
  } else {
    const createResponse = await createInvites({
      data: {
        invitee: [{ email: myEmail, event: dataInfo.id, name: myFullName }],
        isHostMessage: false,
      },
    });
    await addInvitee({
      payload: {
        eventId: dataInfo.id,
        inviteId: createResponse.data.data[0].invitationId,
      },
    });
    navigateVal(navValue);
  }
};

export const postEvent = async (dataInfo) => {
  // The populate=deep because BE logic
  return myAxios.post("events?populate=deep,2", dataInfo);
};

export const putEvent = async (actualId, dataInfo) => {
  return myAxios.put(`events/${actualId}`, dataInfo);
};

export const createInvites = async (dataInfo) => {
  return myAxios.post("custom/add-invitees", dataInfo);
};

export const confirmSlot = async (dataInfo) => {
  return myAxios.post("custom/confirm-slot", dataInfo);
};

export const addInvitee = async (dataInfo) => {
  return myAxios.post("custom/add-user-invitee", dataInfo);
};

export const respondInvitation = async (dataInfo) => {
  return myAxios.post("custom/respond-invitation", dataInfo);
};

export const sendMeeting = async (dataInfo) => {
  return myAxios.post("custom/send-meeting", dataInfo);
};

export const deliverMessage = async (dataInfo) => {
  return myAxios.post("custom/deliver-message", dataInfo);
};

export const modMeeting = async (dataInfo) => {
  return myAxios.post("custom/mod-meeting", dataInfo);
};

export const deleteInvitee = async (inviteeId) => {
  return myAxios.delete(`invitees/${inviteeId}`);
};

export const putInvitee = async (actualId, dataInfo) => {
  return myAxios.put(`invitees/${actualId}`, dataInfo);
};

export const getDecisions = async (inviteeId) => {
  return myAxios.get(`decisions?filters[invitees][id][$eq]=${inviteeId}`);
};

export const postDecision = async (dataInfo) => {
  return myAxios.post("decisions", dataInfo);
};

export const postSlot = async (dataInfo) => {
  return myAxios.post("slots", dataInfo);
};

export const putSlot = async (actualId, dataInfo) => {
  return myAxios.put(`slots/${actualId}`, dataInfo);
};
