import { format } from "date-fns";

// "XX XXX XX XX XX XXX XX XX XX XXX XX XX XX XXX XX XX XX"
export const extraText = "";
// 10000
export const extraNumber = 0;
export const suffixIso = "T00:00:00.000Z";
export const suffixMili = ":00.000";
export const eventList = "event-list/";
export const eventDetail = "event-detail/";
export const noInviteMessage =
  "Invitation may be processed. Invalid parameter.";
const pendingKind = 2;

const getKind = (eventKind, dataInfo, userId) => {
  if (eventKind === pendingKind) {
    return dataInfo.filter((x) => x.attributes.isPending);
  } else if (eventKind === 0) {
    return dataInfo.filter((x) => x.attributes.host.data.id === userId);
  } else {
    return dataInfo;
  }
};

export const isPend = (myAttributes, userEmail) => {
  const myInvites = myAttributes.invitees.data.filter(
    (x) => x.attributes.userEmail === userEmail
  );
  return myInvites.length > 0
    ? myInvites[0].attributes.responded === "pending"
    : false;
};

export const sortReunions = (eventKind, actualReunions, userId, userEmail) => {
  const realMeets = actualReunions.data.data.filter(
    (x) => x.attributes.isDeleted !== true
  );

  const actualData = getKind(eventKind, realMeets, userId);
  const resultData = actualData.map((x) => {
    x.attributes.slots.data.sort((a, b) => {
      return a.attributes.startDateTime.localeCompare(
        b.attributes.startDateTime
      );
    });
    return x;
  });

  return resultData.sort((firstVal, secondVal) => {
    const a = isPend(firstVal.attributes, userEmail);
    const b = isPend(secondVal.attributes, userEmail);
    return !a && b ? 1 : a === b ? 0 : -1;
  });
};

export const getDub = (myAttributes) => {
  const titleList = [];
  if (myAttributes.firstName != null) {
    titleList.push(myAttributes.firstName);
  }

  if (myAttributes.lastName != null) {
    titleList.push(myAttributes.lastName);
  }

  if (titleList.length > 0) {
    return titleList.join(" ");
  } else {
    return myAttributes.email.split("@")[0];
  }
};

export const isInviteInvalid = (errorCatch) =>
  // Lessen optional chaining
  errorCatch.response?.data?.message === "Invalid invitation id.";

export const scrollStart = (x) => {
  x.scrollIntoView();
  const offsetY = -125;
  window.scrollBy(0, offsetY);
};

export const generateGuid = () => {
  const s4 = () =>
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const toUtc = (dateVal) => {
  return new Date(
    Date.UTC(
      dateVal.getUTCFullYear(),
      dateVal.getUTCMonth(),
      dateVal.getUTCDate(),
      dateVal.getUTCHours(),
      dateVal.getUTCMinutes(),
      dateVal.getUTCSeconds()
    )
  );
};

export const showDate = (dateString) =>
  format(new Date(dateString), "EEEE, MMMM d, yyyy");

export const showTime = (dateString) =>
  `${format(new Date(dateString), "h:mm a")}`;
