import React, { memo } from "react";
import { TextFieldElement } from "react-hook-form-mui";
import { c2 } from "../../theme/metrics";

const TimeCreate = ({ name, label }) => {
  return (
    <TextFieldElement
      name={name}
      label={label}
      variant="standard"
      fullWidth
      type="time"
      required
      InputLabelProps={{ shrink: true }}
      sx={{ maxWidth: c2 }}
    />
  );
};

export default memo(TimeCreate);
