import React, { useEffect, useState, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { googleCallback } from "../../api/auth";
import { useLocalStorage } from "../../api/useLocalStorage";
import GoHome from "./GoHome";
import LoadingCard from "./LoadingCard";
import { getLinkedEvent, addInvitee } from "../../api/strapi";
import ErrorPage from "../prime/ErrorPage";
import { getDub, isInviteInvalid, noInviteMessage } from "../../util";
import { useAppContext } from "../../router/AppContext";

const TokenPage = () => {
  const [isPageError, setIsPageError] = useState(false);
  const [vetoValue, setVetoValue] = useState();
  const [, setUserData] = useLocalStorage("userData", null);
  const [tempLink, setTempLink] = useLocalStorage("tempLink", null);
  const [tempInvitee, setTempInvitee] = useLocalStorage("tempInvitee", null);
  const { search } = useLocation();
  const { setSnackMessage } = useAppContext();
  const navigateVal = useNavigate();

  useEffect(() => {
    const startFetch = async () => {
      try {
        const myResponse = await googleCallback(search);
        setUserData(myResponse.data);

        if (tempInvitee != null) {
          await addInvitee(tempInvitee);
          setTempInvitee(null);
        }

        if (tempLink != null) {
          const eventLink = tempLink;
          setTempLink(null);
          await getLinkedEvent(
            eventLink,
            myResponse.data.user.email,
            getDub(myResponse.data.user),
            (x) => {
              window.location.href = x;
            }
          );
        } else {
          window.location.href = "/";
        }
      } catch (errorCatch) {
        // Lessen optional chaining
        if (errorCatch.response?.data?.error?.message != null) {
          setVetoValue(errorCatch.response.data.error.message);
          return;
        }

        if (isInviteInvalid(errorCatch)) {
          setSnackMessage(noInviteMessage);
          navigateVal("/");
          return;
        }

        setIsPageError(true);
      }
    };

    startFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (vetoValue != null) {
    return <GoHome>{vetoValue}</GoHome>;
  }

  if (isPageError) {
    return <ErrorPage />;
  }

  return <LoadingCard />;
};

export default memo(TokenPage);
