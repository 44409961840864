import React, { memo } from "react";
import GridItem from "../shared/GridItem";
import VoteChart from "./VoteChart";
import { a8 } from "../../theme/metrics";

const WidthChart = ({ dataVal, maxVal, tinyWidth, bigWidth }) => (
  <>
    <GridItem sx={{ display: { xs: "block", lg: "none" }, marginRight: a8 }}>
      <VoteChart dataVal={dataVal} maxVal={maxVal} chartWidth={tinyWidth} />
    </GridItem>
    <GridItem sx={{ display: { xs: "none", lg: "block" }, marginRight: a8 }}>
      <VoteChart dataVal={dataVal} maxVal={maxVal} chartWidth={bigWidth} />
    </GridItem>
  </>
);

export default memo(WidthChart);
