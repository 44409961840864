import React, { memo } from "react";
import {
  firstFaceDark,
  firstFaceLight,
  firstGreenDark,
  firstGreenLight,
} from "../../theme/colors";
import { BarChart, Bar, XAxis, YAxis, LabelList } from "recharts";
import { useLocalStorage } from "../../api/useLocalStorage";

const VoteChart = ({ dataVal, maxVal, chartWidth }) => {
  const [darkMode] = useLocalStorage("darkMode", false);
  const myFirst = darkMode ? firstGreenDark : firstGreenLight;

  return (
    <BarChart
      width={chartWidth}
      height={48}
      data={[{ dataVal }]}
      layout="vertical"
    >
      <Bar
        dataKey="dataVal"
        fill={myFirst}
        style={{
          filter: `drop-shadow(0px 0px 4px ${myFirst})`,
        }}
      >
        <LabelList
          dataKey="dataVal"
          position="insideRight"
          style={{ fill: darkMode ? firstFaceDark : firstFaceLight }}
        />
      </Bar>
      <XAxis type="number" hide domain={[0, maxVal]} />
      <YAxis type="category" hide />
    </BarChart>
  );
};

export default memo(VoteChart);
