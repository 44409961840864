import React, { memo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "../components/prime/LandingPage";
import TokenPage from "../components/shared/TokenPage";
import TokenLimitedPage from "../components/prime/TokenLimitedPage";

const PublicRouter = () => (
  <Routes>
    <Route path="/login" element={<LandingPage />} />
    <Route path={`/limited`} element={<TokenLimitedPage />} />
    <Route path={`/link`} element={<LandingPage />} />
    <Route path="/" element={<LandingPage />} />
    <Route path={`/token`} element={<TokenPage />} />
    <Route path="*" element={<Navigate to={"/"} />} />
  </Routes>
);

export default memo(PublicRouter);
