import React, { memo } from "react";
import EventDetail from "./EventDetail";

const ReunionDetail = ({ theEvent, setTribeList }) => (
  <>
    {theEvent.attributes.isDeleted && <>Canceled event</>}
    {!theEvent.attributes.isDeleted && (
      <EventDetail theEvent={theEvent} setTribeList={setTribeList} />
    )}
  </>
);

export default memo(ReunionDetail);
