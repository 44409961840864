import React, { memo, useState } from "react";
import GridItem from "../shared/GridItem";
import { Box, Button, Checkbox } from "@mui/material";
import { format } from "date-fns";
import { Done, DoneOutline } from "@mui/icons-material";
import LoadingPicture from "../shared/LoadingPicture";
import { a5, b6 } from "../../theme/metrics";
import { getDecisions, putInvitee } from "../../api/strapi";
import { useAppContext } from "../../router/AppContext";
import { useLocalStorage } from "../../api/useLocalStorage";
import BaseModal from "../shared/BaseModal";
import AcceptVote from "../main/AcceptVote";
import WidthChart from "../main/WidthChart";
import GridPrime from "../shared/GridPrime";

const pairBase = 2;
const tinyWidth = 90;
const bigWidth = 360;
const halfMargin = 4;
const VoteSlot = ({
  myId,
  myAttributes,
  actualIndex,
  eventId,
  inviteeId,
  isHost,
  maxVal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [userData] = useLocalStorage("userData", null);
  const { setIsError } = useAppContext();
  const isChecked =
    myAttributes.invitees.data.filter(
      (x) => x.attributes.email === userData.user.email
    ).length > 0;

  return (
    <GridItem
      className="backgroundItem"
      sx={{
        backgroundColor:
          actualIndex % pairBase === 0 ? "secondary.main" : undefined,
      }}
    >
      <GridPrime justifyContent="space-between" wrap="nowrap">
        <GridItem>
          <GridPrime>
            <GridItem>
              <Checkbox
                size="small"
                icon={<DoneOutline />}
                checkedIcon={<Done sx={{ fontSize: b6 }} />}
                onChange={async () => {
                  if (isLoading) {
                    return;
                  } else {
                    setIsLoading(true);
                    try {
                      const myResponse = await getDecisions(inviteeId);
                      const previousDecis = myResponse.data.data;

                      let decisions;
                      if (isChecked) {
                        decisions = previousDecis
                          .map((x) => x.id)
                          .filter((x) => x !== myId);
                      } else {
                        decisions = [...previousDecis.map((x) => x.id), myId];
                      }

                      await putInvitee(inviteeId, {
                        data: {
                          decisions,
                        },
                      });
                      // eslint-disable-next-line no-restricted-globals
                      location.reload();
                    } catch {
                      setIsError(true);
                    } finally {
                      setIsLoading(false);
                    }
                  }
                }}
                checked={isChecked || isLoading}
              />
            </GridItem>
            {!isLoading && (
              <GridItem>
                <Box>
                  {format(new Date(myAttributes.startDateTime), "EEEE, MMMM d")}
                </Box>
                <Box>
                  {format(new Date(myAttributes.startDateTime), "h:mm a")} for{" "}
                  {myAttributes.durationHours}{" "}
                  {myAttributes.durationHours > 1 ? "hours" : "hour"}
                </Box>
              </GridItem>
            )}
            {isLoading && (
              <GridItem>
                <LoadingPicture />
              </GridItem>
            )}
          </GridPrime>
        </GridItem>
        {(!isHost || !isChecked) && (
          <WidthChart
            dataVal={myAttributes.invitees.data.length}
            maxVal={maxVal}
            tinyWidth={tinyWidth}
            bigWidth={bigWidth}
          />
        )}
        {isHost && isChecked && (
          <GridItem>
            <Box
              sx={{
                display: { xs: "block", lg: "none" },
                width: tinyWidth + halfMargin,
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{ marginRight: a5 }}
                onClick={() => setIsAccept(true)}
              >
                Send
              </Button>
              {myAttributes.invitees.data.length}
            </Box>
            <Box
              sx={{
                display: { xs: "none", lg: "block" },
                width: bigWidth + halfMargin,
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{ marginRight: a5 }}
                onClick={() => setIsAccept(true)}
              >
                Send Calendar Invite
              </Button>
              {myAttributes.invitees.data.length}
            </Box>
          </GridItem>
        )}
      </GridPrime>
      <BaseModal
        isOpen={isAccept}
        handleClose={() => setIsAccept(false)}
        right="0"
        transform="translate(0, -50%)"
      >
        <AcceptVote myAttributes={myAttributes} eventId={eventId} />
      </BaseModal>
    </GridItem>
  );
};

export default memo(VoteSlot);
