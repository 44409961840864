import React, { memo, useState } from "react";
import { Typography } from "@mui/material";
import GridItem from "../shared/GridItem";
import InviteInfo from "./InviteInfo";
import GridCol from "../shared/GridCol";

const ShowInvites = ({ tribeList }) => {
  const [selectedId, setSelectedId] = useState(-1);

  // TODO move ShowInvites to each EventDetail component??
  return (
    <>
      <Typography variant="h5">Invitees</Typography>
      <GridCol>
        <GridItem>View all people</GridItem>
        {tribeList.map((x) => (
          <InviteInfo
            key={x.id}
            myAtttributes={x.attributes}
            myId={x.id}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
        ))}
      </GridCol>
    </>
  );
};

export default memo(ShowInvites);
