import React, { useState, useEffect, memo } from "react";
import EventList from "./EventList";
import { useLocation, useParams } from "react-router-dom";
import { Box, Grid, Button } from "@mui/material";
import CreateEvent from "../event/CreateEvent";
import { getEvents } from "../../api/strapi";
import ErrorPage from "../prime/ErrorPage";
import LoadingCard from "../shared/LoadingCard";
import { b5, sizeBreak } from "../../theme/metrics";
import { useLocalStorage } from "../../api/useLocalStorage";
import DrawerCard from "../prime/DrawerCard";
import CreateInvite from "./CreateInvite";
import { eventList } from "../../util";
import GridItem from "../shared/GridItem";
import GoHome from "../shared/GoHome";
import { useAppContext } from "../../router/AppContext";
import ShortLayout from "../prime/ShortLayout";
import ShowInvites from "../event/ShowInvites";
import BaseModal from "../shared/BaseModal";
import ReunionDetail from "../detail/ReunionDetail";
import GridCol from "../shared/GridCol";

const EventsPage = () => {
  const [heightVal, setHeightVal] = useState(b5);
  const [tribeList, setTribeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMedium, setIsMedium] = useState(false);
  const [reunionArray, setReunionArray] = useState([]);
  const [userData] = useLocalStorage("userData", null);
  const [eventKind, setEventKind] = useLocalStorage("eventKind", 1);
  const [tempReunions, setTempReunions] = useLocalStorage("tempReunions", null);
  const {
    isError,
    setIsError,
    reunionData,
    setMeetData,
    matchInfo,
    setMatchInfo,
  } = useAppContext();
  const { listIndex } = useParams();
  const { pathname } = useLocation();

  const isFilled = !isLoading && reunionArray.length > 0;

  const changeKind = (kindValue) => {
    setEventKind(kindValue);
    window.location.href = "/";
  };

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const firstEvents = async () => {
      let meetList;
      setIsLoading(true);
      try {
        if (tempReunions == null) {
          meetList = await getEvents(
            eventKind,
            userData.user.id,
            userData.user.email,
            undefined
          );
        } else {
          meetList = tempReunions;
          setTempReunions(null);
        }

        setReunionArray(meetList);

        // Don't persist value after reloads, so reset it
        setEventKind(1);
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    firstEvents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    const handleResize = () => {
      setIsMedium(window.innerWidth >= sizeBreak);

      // Modify if vertical space changes
      const otherHeight = 112;
      setHeightVal(`${window.innerHeight - otherHeight}px`);
    };

    // https://stackoverflow.com/a/36862446/6227407
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isError) {
    return <ErrorPage />;
  }

  if (
    !isLoading &&
    reunionArray.length > 0 &&
    !reunionArray.some((x) => {
      let myValue = {};
      myValue = x;
      return myValue.id.toString() === listIndex;
    })
  ) {
    return <GoHome>Event not found.</GoHome>;
  }

  return (
    <>
      <DrawerCard changeKind={changeKind}>
        {isLoading && <LoadingCard />}
        {isFilled && !isMedium && (
          <ShortLayout
            isList={pathname.indexOf(eventList) >= 0}
            reunionArray={reunionArray}
            setTribeList={setTribeList}
          />
        )}
        {isFilled && isMedium && (
          <Grid container spacing={2}>
            <GridItem xs={5}>
              <Box sx={{ overflowY: "auto", height: heightVal }}>
                <EventList reunionArray={reunionArray} />
              </Box>
            </GridItem>
            <GridItem xs={7}>
              <Box sx={{ overflowY: "auto", height: heightVal }}>
                <ReunionDetail
                  theEvent={
                    reunionArray.filter((x) => {
                      let y = {};
                      y = x;
                      return y.id.toString() === listIndex;
                    })[0]
                  }
                  setTribeList={setTribeList}
                />
              </Box>
            </GridItem>
          </Grid>
        )}
        {!isLoading && reunionArray.length === 0 && (
          <GridCol alignItems="center">
            <GridItem>
              <Box>No event found.</Box>
            </GridItem>
            <GridItem>
              <Box>Add your own event:</Box>
            </GridItem>
            <GridItem>
              <Button
                variant="contained"
                size="large"
                endIcon={<img alt="img" src="/addIcon4.svg" />}
                onClick={() => {
                  setMatchInfo(null);
                }}
              >
                Create an Event
              </Button>
            </GridItem>
          </GridCol>
        )}
      </DrawerCard>
      {!isLoading && (
        <>
          <BaseModal
            isOpen={matchInfo !== undefined}
            handleClose={() => setMatchInfo(undefined)}
            right="0"
            transform="translate(0, -50%)"
            noClose
          >
            <CreateEvent headerNull={null} />
          </BaseModal>
          <BaseModal
            isOpen={reunionData != null}
            handleClose={() => setMeetData(null)}
            noClose
          >
            <CreateInvite />
          </BaseModal>
          <BaseModal
            isOpen={tribeList.length > 0}
            handleClose={() => setTribeList([])}
            right="0"
            transform="translate(0, -50%)"
          >
            <ShowInvites tribeList={tribeList} />
          </BaseModal>
        </>
      )}
    </>
  );
};

export default memo(EventsPage);
