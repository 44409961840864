import React, { memo } from "react";
import { Box } from "@mui/material";
import { a8, a5, b1, b2 } from "../../theme/metrics";

const TextCard = ({ children, backgroundColor, color }) => (
  <Box
    sx={{
      paddingTop: a5,
      paddingBottom: a5,
      paddingLeft: b1,
      paddingRight: b1,
      borderRadius: a8,
      fontSize: b2,
      backgroundColor,
      color,
    }}
  >
    {children}
  </Box>
);

export default memo(TextCard);
