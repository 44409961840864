import React, { memo, useEffect, useState } from "react";
import { Box, Checkbox } from "@mui/material";
import { extraText } from "../../util";
import { Done, DoneOutline, Warning } from "@mui/icons-material";
import GridItem from "../shared/GridItem";
import { format } from "date-fns";
import { confirmSlot } from "../../api/strapi";
import { useAppContext } from "../../router/AppContext";
import GridPrime from "../shared/GridPrime";
import { b3, b6 } from "../../theme/metrics";
import LoadingPicture from "../shared/LoadingPicture";

const pairBase = 2;
const dailyHeader = 2;
const monthlyHeader = 3;

const TimeSlot = ({
  eventId,
  myAttributes,
  slotId,
  actualIndex,
  allList,
  peopleLength,
  hostEmail,
}) => {
  // 0 No daily
  // 1 No monthly
  // 2 Header daily
  // 3 Header monthly
  const [headerType, setHeaderType] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const { setIsError } = useAppContext();
  const busyList = JSON.parse(myAttributes.notAvailable);
  const countBusy = busyList.length;
  const isHostBusy = busyList.some((x) => x === hostEmail);

  const handleConfirm = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      await confirmSlot({
        event: eventId,
        slotId,
      });

      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (actualIndex === 0) {
      if (
        allList.length > 1 &&
        format(new Date(allList[1].attributes.startDateTime), "hh") ===
          format(new Date(myAttributes.startDateTime), "hh")
      ) {
        setHeaderType(monthlyHeader);
      } else {
        setHeaderType(dailyHeader);
      }
      return;
    }

    const dateTime = new Date(
      allList[actualIndex - 1].attributes.startDateTime
    );
    const prevHour = format(dateTime, "hh");
    const prevDay = format(dateTime, "dd");

    const firstTime = new Date(myAttributes.startDateTime);
    if (prevHour !== format(firstTime, "hh")) {
      if (prevDay !== format(firstTime, "dd")) {
        setHeaderType(dailyHeader);
        return;
      }
      setHeaderType(0);
    } else {
      const prevMonth = format(dateTime, "MM");
      if (prevMonth !== format(firstTime, "MM")) {
        setHeaderType(monthlyHeader);
        return;
      }
      setHeaderType(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {headerType > 1 && (
        <GridItem
          className="backgroundItem"
          sx={{
            position: "sticky",
            backgroundColor:
              actualIndex % pairBase === 0
                ? "secondary.main"
                : "background.paper",
            zIndex: "1",
            top: { xs: "64px", lg: 0 },
          }}
        >
          {format(
            new Date(myAttributes.startDateTime),
            headerType === monthlyHeader ? "h:mm a • MMMM" : "EEEE, MMMM d"
          )}
        </GridItem>
      )}
      <GridItem
        className="backgroundItem"
        sx={{
          backgroundColor:
            actualIndex % pairBase === 0 ? "secondary.main" : undefined,
        }}
      >
        <GridPrime>
          <GridItem>
            <Checkbox
              size="small"
              icon={<DoneOutline />}
              checkedIcon={<Done sx={{ fontSize: b6 }} />}
              onChange={handleConfirm}
            />
          </GridItem>
          {!isLoading && (
            <GridItem>
              <Box>
                {format(
                  new Date(myAttributes.startDateTime),
                  [1, monthlyHeader].includes(headerType) ? "EEEE d" : "h:mm a"
                )}{" "}
                •{" "}
                {format(
                  new Date(myAttributes.endDateTime),
                  [1, monthlyHeader].includes(headerType) ? "EEEE d" : "h:mm a"
                )}
                {extraText}
                {isHostBusy && (
                  <>
                    {" "}
                    <Warning
                      sx={{ fontSize: b3, color: "red", marginBottom: "-2px" }}
                    />
                  </>
                )}
              </Box>
              <Box>
                Available {peopleLength - countBusy} • Busy{" "}
                <span style={countBusy > 0 ? { color: "red" } : undefined}>
                  {countBusy}
                  {extraText}
                </span>
                {isHostBusy ? " including host" : ""}
              </Box>
            </GridItem>
          )}
          {isLoading && (
            <GridItem>
              <LoadingPicture />
            </GridItem>
          )}
        </GridPrime>
      </GridItem>
    </>
  );
};

export default memo(TimeSlot);
