import React, { memo } from "react";
import { Typography } from "@mui/material";
import GridItem from "../shared/GridItem";
import GridPrime from "../shared/GridPrime";

const PictureInfo = ({ titleName, children, src }) => (
  <GridPrime justifyContent="space-between" wrap="nowrap">
    <GridItem>
      <Typography variant="h5">{titleName}</Typography>
      {children}
    </GridItem>
    <GridItem>
      <img alt="img" style={{ width: "115px" }} src={src} />
    </GridItem>
  </GridPrime>
);

export default memo(PictureInfo);
