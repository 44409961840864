import React, { memo } from "react";
import { Grid } from "@mui/material";

const GridCol = ({ children, ...myProps }) => (
  <Grid container spacing={2} direction="column" {...myProps}>
    {children}
  </Grid>
);

export default memo(GridCol);
