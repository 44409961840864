import React, { useEffect, useState, memo } from "react";
import ErrorPage from "./ErrorPage";
import LoadingCard from "../shared/LoadingCard";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getLinkedEvent } from "../../api/strapi";
import { useLocalStorage } from "../../api/useLocalStorage";
import { getDub } from "../../util";

const GoLink = () => {
  const [isPageError, setIsPageError] = useState(false);
  const [userData] = useLocalStorage("userData", null);
  const [searchParams] = useSearchParams();
  const navigateVal = useNavigate();

  useEffect(() => {
    const startFetch = async () => {
      try {
        await getLinkedEvent(
          searchParams.get("link"),
          userData.user.email,
          getDub(userData.user),
          navigateVal
        );
      } catch {
        setIsPageError(true);
      }
    };

    startFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPageError) {
    return <ErrorPage />;
  }

  return <LoadingCard />;
};

export default memo(GoLink);
