import React, { memo, Fragment } from "react";
import EventTop from "./EventTop";
import GridCol from "../shared/GridCol";
import GridItem from "../shared/GridItem";
import { Divider, Typography } from "@mui/material";
import { isPend } from "../../util";
import { useLocalStorage } from "../../api/useLocalStorage";

const EventList = ({ reunionArray }) => {
  const [userData] = useLocalStorage("userData", null);
  const dividerIndex = reunionArray.findIndex(
    (x) => !isPend(x.attributes, userData.user.email)
  );

  return (
    <GridCol>
      {reunionArray.map((x, i) => (
        <Fragment key={x.id}>
          {i === 0 && isPend(x.attributes, userData.user.email) && (
            <GridItem>
              <Typography variant="h5">Awaiting Response</Typography>
            </GridItem>
          )}
          {i > 0 && i === dividerIndex && (
            <GridItem xs={12}>
              <Divider color="error" />
            </GridItem>
          )}
          <EventTop
            myAttributes={x.attributes}
            reunionIndex={x.id.toString()}
          />
        </Fragment>
      ))}
    </GridCol>
  );
};

export default memo(EventList);
