import React, { memo } from "react";
import { Box } from "@mui/material";
import LoadingPicture from "./LoadingPicture";

const LoadingCard = () => (
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <LoadingPicture />
  </Box>
);

export default memo(LoadingCard);
