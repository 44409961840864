import React, { memo } from "react";
import { Box, Toolbar } from "@mui/material";
import DrawerBar from "./DrawerBar";
import { drawerWidth } from "../../theme/metrics";

const DrawerCard = ({ children, changeKind }) => (
  <Box sx={{ display: "flex" }}>
    <DrawerBar changeKind={changeKind} />
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { lg: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      <Toolbar />
      {children}
    </Box>
  </Box>
);

export default memo(DrawerCard);
