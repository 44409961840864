import React, { memo, useCallback, useState } from "react";
import { Button, Typography } from "@mui/material";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import "react-datepicker/dist/react-datepicker.css";
import LoadingPicture from "../shared/LoadingPicture";
import { createInvites } from "../../api/strapi";
import GridItem from "../shared/GridItem";
import { useAppContext } from "../../router/AppContext";
import GridCol from "../shared/GridCol";
import _ from "lodash";
import { produce } from "immer";
import CopyLink from "../detail/CopyLink";
import BackTitle from "./BackTitle";
import PhoneInvite from "../event/PhoneInvite";

const enableTime = 2000;
const emptyArray = [];

const CreateInvite = () => {
  const [wizStep, setWizStep] = useState(1);
  const [dataList, setDataList] = useState(emptyArray);
  const [isUnsend, setIsUnsend] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { setIsError, reunionData, setMeetData, setSnackMessage } =
    useAppContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const enableUnsend = useCallback(
    _.debounce((x) => {
      // Prevent misfiring Save button
      setIsUnsend(x);
    }, enableTime),
    []
  );

  const handleSend = async () => {
    const event = reunionData.id;
    const invitee = dataList.map(
      ({ nameVal: name, email, phone: phoneVal }) => {
        const phone = `+1${phoneVal}`;
        if (
          phoneVal != null &&
          phoneVal.length > 0 &&
          email != null &&
          email.length > 0
        ) {
          return { email, phone, event, name };
        } else if (phoneVal != null && phoneVal.length > 0) {
          return { phone, event, name };
        } else {
          return { email, event, name };
        }
      }
    );

    setIsLoading(true);
    try {
      await createInvites({
        data: { invitee, isHostMessage: true },
      });
      setMeetData(null);
      setSnackMessage("Invitations sent!");

      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = (actualData) => {
    setDataList(
      produce((x) => {
        x.push(actualData);
      })
    );
    setWizStep(0);
    enableUnsend(false);
  };

  return (
    <>
      {wizStep === 0 && (
        <>
          <Typography variant="h5">Invite</Typography>
          <GridCol>
            <CopyLink />
            {dataList.map((x, i) => (
              <GridItem key={`g_${i.toString()}`}>{x.name}</GridItem>
            ))}
            <GridItem>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  setWizStep(1);
                  setIsUnsend(true);
                }}
              >
                Add Guest
              </Button>
            </GridItem>
            <GridItem>
              {!isLoading && (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={dataList.length === 0 || isUnsend}
                  onClick={handleSend}
                >
                  Send Invites
                </Button>
              )}
              {isLoading && <LoadingPicture />}
            </GridItem>
          </GridCol>
        </>
      )}
      {wizStep === 1 && (
        <FormContainer
          FormProps={{ autoComplete: "off" }}
          onSuccess={handleAdd}
        >
          <BackTitle
            onClick={() => {
              setWizStep(0);
              enableUnsend(false);
            }}
          >
            Invite
          </BackTitle>
          <GridCol>
            <CopyLink />
            <GridItem>Enter email or phone to send invitation link</GridItem>
            <GridItem>
              <TextFieldElement
                name="nameVal"
                label="Name"
                required
                variant="standard"
                fullWidth
                autoFocus
              />
            </GridItem>
            <GridItem>
              <TextFieldElement
                name="email"
                label="Email"
                required
                type="email"
                variant="standard"
                fullWidth
              />
            </GridItem>
            <PhoneInvite />
            <GridItem>
              <Button type="submit" variant="contained" size="large" fullWidth>
                Add Guest
              </Button>
            </GridItem>
          </GridCol>
        </FormContainer>
      )}
    </>
  );
};

export default memo(CreateInvite);
