import axios from "axios";

const myAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "api/",
});

export const googleCallback = async (searchVal) => {
  return myAxios.get(`auth/google/callback${searchVal}`);
};

export const passwordlessLogin = async (tokenEmail) => {
  return myAxios.get(`passwordless/login?loginToken=${tokenEmail}`);
};
