import React, { memo, useState } from "react";
import { Button, Typography } from "@mui/material";
import GridItem from "../shared/GridItem";
import LoadingPicture from "../shared/LoadingPicture";
import { addHours } from "date-fns";
import { postSlot, sendMeeting } from "../../api/strapi";
import { useAppContext } from "../../router/AppContext";
import GridCol from "../shared/GridCol";

const AcceptVote = ({ myAttributes, eventId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setIsError } = useAppContext();

  return (
    <>
      <Typography variant="h5">Date Poll</Typography>
      <GridCol>
        <GridItem>Send calendar invite?</GridItem>
        <GridItem>
          {!isLoading && (
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={async () => {
                const startDateTime = new Date(myAttributes.startDateTime);
                const endDateTime = addHours(
                  startDateTime,
                  myAttributes.durationHours
                );

                setIsLoading(true);
                try {
                  await postSlot({
                    data: {
                      isSelected: true,
                      startDateTime,
                      endDateTime,
                      event: eventId,
                    },
                  });

                  // startDate example '2023-08-18T02:00:00-07:00'
                  await sendMeeting({
                    eventId,
                    startDateTime,
                    endDateTime,
                  });

                  // eslint-disable-next-line no-restricted-globals
                  location.reload();
                } catch {
                  setIsError(true);
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              Send
            </Button>
          )}
          {isLoading && <LoadingPicture />}
        </GridItem>
      </GridCol>
    </>
  );
};

export default memo(AcceptVote);
