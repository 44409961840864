// https://mui.com/material-ui/react-drawer/#responsive-drawer

import React, { memo, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Avatar, Button, MenuItem, Select } from "@mui/material";
import { firstYellow } from "../../theme/colors";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../../api/useLocalStorage";
import { b2, b3, b7, drawerWidth } from "../../theme/metrics";
import { eventDetail, eventList, getDub, scrollStart } from "../../util";
import packageInfo from "../../../package.json";
import { useAppContext } from "../../router/AppContext";

const VerticalText = ({ textVal }) => (
  <ListItemText
    primary={textVal}
    sx={{
      "& .MuiTypography-root": {
        fontSize: b2,
      },
    }}
  />
);

const VerticalItem = ({ textVal, onClick }) => (
  <ListItem disablePadding>
    <ListItemButton onClick={onClick}>
      <VerticalText textVal={textVal} />
    </ListItemButton>
  </ListItem>
);

const VerticalButton = ({ children, onClick, endIcon }) => (
  <Box sx={{ marginLeft: b3, marginRight: b3 }}>
    <Button
      variant="contained"
      size="large"
      fullWidth
      endIcon={endIcon}
      onClick={onClick}
    >
      {children}
    </Button>
  </Box>
);

const MenuTool = ({ onClick }) => (
  <IconButton edge="start" onClick={onClick} sx={{ display: { lg: "none" } }}>
    <MenuIcon />
  </IconButton>
);

const TypeTitle = ({
  children,
  // Lessen optional props
  sx = {},
}) => (
  <Typography
    noWrap
    component="div"
    sx={{
      ml: 2,
      flexGrow: 1,
      color: "primary.main",
      ...sx,
    }}
  >
    {children}
  </Typography>
);

const DrawerBar = ({ changeKind }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { setMatchInfo } = useAppContext();
  const navigateVal = useNavigate();
  const { pathname } = useLocation();
  const { listIndex } = useParams();
  const [userData] = useLocalStorage("userData", null);
  const [eventKind] = useLocalStorage("eventKind", 1);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleKind = (thisEvent) => {
    changeKind(thisEvent.target.value);
  };

  const drawer = (
    <>
      <Toolbar sx={{ justifyContent: "center" }}>
        <img
          alt="img"
          title={`v${packageInfo.version}`}
          src="/gatsbylogo4.svg"
        />
      </Toolbar>
      <Divider />
      <List sx={{ flexGrow: 1 }}>
        <ListItem>
          <VerticalText textVal={"Beta"} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Avatar
              alt={getDub(userData.user)}
              src={userData.user.picture}
              sx={{ width: b7, height: b7 }}
            />
          </ListItemIcon>
          <ListItemText primary={getDub(userData.user)} />
        </ListItem>
        <VerticalButton
          endIcon={<img alt="img" src="/addIcon4.svg" />}
          onClick={() => {
            setMobileOpen(false);
            setMatchInfo(null);
          }}
        >
          Create an Event
        </VerticalButton>
      </List>
      <List>
        <VerticalItem
          textVal={"Contact Us"}
          onClick={() =>
            window.open(
              "mailto:info@thegatsbyapp.com?subject=Gatsby%20|%20Contact%20Form",
              "_blank"
            )
          }
        />
        <VerticalItem
          textVal={"Privacy Policy"}
          onClick={() =>
            window.open("https://www.thegatsbyapp.com/privacy", "_blank")
          }
        />
        <VerticalItem
          textVal={"Terms & Conditions"}
          onClick={() =>
            window.open("https://www.thegatsbyapp.com/terms", "_blank")
          }
        />
        <VerticalButton
          endIcon={undefined}
          onClick={() => {
            localStorage.clear();
            window.location.href = "/";
          }}
        >
          Logout
        </VerticalButton>
        <ListItem>
          <VerticalText
            textVal={`© ${new Date().getFullYear()} Gatsby, All Rights Reserved`}
          />
        </ListItem>
      </List>
    </>
  );

  useEffect(() => {
    const myElement = document.getElementById("scrollDetail");
    if (myElement != null) {
      const materialSlideTime = 200;
      setTimeout(() => scrollStart(myElement), materialSlideTime);
    }
  }, [pathname]);

  return (
    <>
      <AppBar
        color="inherit"
        position="fixed"
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          {pathname.indexOf(eventDetail) >= 0 && (
            <>
              <IconButton
                edge="start"
                onClick={() => navigateVal(`/${eventList}${listIndex}`)}
                sx={{ display: { lg: "none" } }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <TypeTitle sx={{ display: { xs: "block", lg: "none" } }}>
                Event
              </TypeTitle>
              <TypeTitle sx={{ display: { xs: "none", lg: "block" } }}>
                My Events
              </TypeTitle>
            </>
          )}
          {pathname.indexOf(eventDetail) < 0 && (
            <>
              <MenuTool onClick={handleDrawerToggle} />
              <TypeTitle>My Events</TypeTitle>
            </>
          )}
          <Box sx={{ display: { xs: "none", lg: "block" }, height: "24px" }}>
            <img alt="img" src="/handwave4.svg" />
          </Box>
          <Typography
            variant="h5"
            sx={{
              display: { xs: "none", lg: "block" },
              marginLeft: 1,
              flexGrow: 1,
              color: firstYellow,
            }}
          >
            Welcome, {getDub(userData.user)}!
          </Typography>
          <Select
            value={eventKind}
            size="small"
            onChange={handleKind}
            sx={{
              display: {
                xs: pathname.indexOf(eventDetail) >= 0 ? "none" : "flex",
                lg: "flex",
              },
              backgroundColor: "primary.main",
              color: "white",
              "& .MuiSvgIcon-root": {
                color: "white",
              },
            }}
          >
            <MenuItem value={1}>All Events</MenuItem>
            <MenuItem value={0}>Hosted Events</MenuItem>
            <MenuItem value={2}>Pending Events</MenuItem>
          </Select>
          {pathname.indexOf(eventDetail) >= 0 && (
            <MenuTool onClick={handleDrawerToggle} />
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          anchor="top"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            // Better open performance on mobile.
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default memo(DrawerBar);
