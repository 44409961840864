import React, { memo } from "react";
import { Routes, Route } from "react-router-dom";
import EventsPage from "../components/main/EventsPage";
import GoHome from "../components/shared/GoHome";
import ReceivePage from "../components/prime/ReceivePage";
import { eventDetail, eventList } from "../util";
import TokenLimitedPage from "../components/prime/TokenLimitedPage";
import FirstElement from "../components/prime/FirstElement";
import GoLink from "../components/prime/GoLink";

const MainRouter = () => (
  <Routes>
    <Route path="/login" element={<ReceivePage />} />
    <Route path={`/limited`} element={<TokenLimitedPage />} />
    <Route path={`/link`} element={<GoLink />} />
    <Route path="/" element={<FirstElement />} />
    <Route path={`/${eventDetail}:listIndex`} element={<EventsPage />} />
    <Route path={`/${eventList}:listIndex`} element={<EventsPage />} />
    <Route path="*" element={<GoHome>Path not found.</GoHome>} />
  </Routes>
);

export default memo(MainRouter);
