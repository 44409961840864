import React, { Fragment, memo, useState } from "react";
import BasicCard from "../shared/BasicCard";
import { a8, b8 } from "../../theme/metrics";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import PersonInfo from "./PersonInfo";
import ResponseButtons from "./ResponseButtons";
import ImgInfo from "./ImgInfo";
import { extraNumber, extraText, showDate, showTime } from "../../util";
import TimeSlot from "./TimeSlot";
import GridItem from "../shared/GridItem";
import { useLocalStorage } from "../../api/useLocalStorage";
import { postDecision, respondInvitation } from "../../api/strapi";
import { useAppContext } from "../../router/AppContext";
import PictureInfo from "./PictureInfo";
import _ from "lodash";
import VoteSlot from "./VoteSlot";
import BallotSlot from "./BallotSlot";
import BaseModal from "../shared/BaseModal";
import LoadingPicture from "../shared/LoadingPicture";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import SwitchBool from "../event/SwitchBool";
import { Add, Edit } from "@mui/icons-material";
import GridPrime from "../shared/GridPrime";
import GridCol from "../shared/GridCol";

const EditIconButton = ({ isShow, ...myProps }) => (
  <>
    {isShow && (
      <IconButton size="small" {...myProps}>
        <Edit />
      </IconButton>
    )}
  </>
);

const EditButton = memo(EditIconButton);

const EventDetail = ({ theEvent, setTribeList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isBallot, setIsBallot] = useState(false);
  const [userData] = useLocalStorage("userData", null);
  const { setIsError, setMeetData, setSnackMessage, setMatchInfo } =
    useAppContext();
  const myAttributes = theEvent.attributes;
  const myId = theEvent.id;
  const isHost = myAttributes.host.data.id === userData.user.id;
  const inviteesData = myAttributes.invitees.data;
  const peopleData = inviteesData.filter((x) => !x.attributes.isHost);
  const peopleLength = peopleData.length;
  const mySlots = myAttributes.slots.data;
  const selectedSlots = mySlots.filter((x) => x.attributes.isSelected === true);
  const myDecisions = myAttributes.decisions.data;
  const myInvites = inviteesData.filter(
    (x) => x.attributes.userEmail === userData.user.email
  );
  const isResponse =
    myInvites.length > 0
      ? myInvites[0].attributes.responded === "pending"
      : false;
  const datePolls = myDecisions.filter(
    (x) => x.attributes.title === "DateVotePoll"
  );
  const isDatePoll = myInvites.length > 0 && datePolls.length > 0;
  const maxDatePoll = Math.max(
    ...datePolls.map((x) => x.attributes.invitees.data.length)
  );
  const customBallots = Object.entries(
    _.groupBy(
      myDecisions.filter((x) => x.attributes.title !== "DateVotePoll"),
      (x) => x.attributes.title
    )
  );

  const handleResponse = async (isPositive) => {
    setIsLoading(true);
    try {
      await respondInvitation({
        payload: { event: myId, accepted: isPositive },
      });
      setSnackMessage(
        isPositive
          ? "Congrats! You have synced your calendar successfully."
          : "Invitation declined."
      );
      if (isPositive) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } else {
        window.location.href = "/";
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BasicCard id="scrollDetail">
      <GridCol
        sx={{
          // TODO use js object
          "&>div.MuiGrid-item.backgroundItem": {
            paddingTop: a8,
            paddingBottom: a8,
            paddingRight: a8,
          },
        }}
      >
        <GridItem>
          <GridPrime justifyContent="space-between" wrap="nowrap">
            <GridItem>
              <Typography variant="h5">
                {myAttributes.title}
                {extraText}
                <EditButton
                  isShow={isHost}
                  onClick={() => setMatchInfo(theEvent)}
                />
              </Typography>
            </GridItem>
            <GridItem>
              <GridPrime>
                {isHost && (
                  <>
                    <GridItem>
                      {peopleLength === 0 && "0 invitees"}
                      {peopleLength > 0 && (
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => setTribeList(peopleData)}
                        >
                          {extraNumber + peopleLength}{" "}
                          {peopleLength === 1 ? "invitee" : "invitees"}
                        </Button>
                      )}
                    </GridItem>
                    <GridItem>
                      <IconButton
                        sx={{
                          backgroundColor: "primary.main",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "primary.main",
                            filter: "brightness(80%)",
                          },
                        }}
                        onClick={() => setMeetData(theEvent)}
                      >
                        <Add />
                      </IconButton>
                    </GridItem>
                  </>
                )}
                {myAttributes.host.data.id !== userData.user.id && (
                  <GridItem>
                    {extraNumber + peopleLength}{" "}
                    {peopleLength === 1 ? "invitee" : "invitees"}
                  </GridItem>
                )}
              </GridPrime>
            </GridItem>
          </GridPrime>
        </GridItem>
        <PersonInfo
          avatarWidth={b8}
          myAttributes={myAttributes.host.data.attributes}
          userUnique={userData.user.email}
          isPending={myAttributes.isPending}
        />
        <ResponseButtons
          isLoading={isLoading}
          isResponse={isResponse}
          handleAccept={() => handleResponse(true)}
          handleDecline={() => handleResponse(false)}
        />
        {myAttributes.isPending === true && isHost && (
          <>
            <GridItem xs={12}>
              <Divider />
            </GridItem>
            <GridItem>
              {myAttributes.algoExecuted === true && mySlots.length === 0 && (
                <PictureInfo
                  titleName="No Time Slots Found"
                  src="/emptySlots4.svg"
                >
                  Did not found any available time slots.
                </PictureInfo>
              )}
              {myAttributes.algoExecuted === false && mySlots.length === 0 && (
                <PictureInfo titleName="Time Slots" src="/slotsempty4.svg">
                  Hang tight. We are searching for everyone’s availability.
                </PictureInfo>
              )}
              {myAttributes.algoExecuted === false && mySlots.length > 0 && (
                <>
                  <Typography variant="h5">Time Slots</Typography>
                  People Availability
                </>
              )}
            </GridItem>
            {mySlots.map((x, i, allList) => (
              <TimeSlot
                key={x.id}
                actualIndex={i}
                allList={allList}
                myAttributes={x.attributes}
                slotId={x.id}
                eventId={myId}
                peopleLength={peopleLength}
                hostEmail={myAttributes.host.data.attributes.email}
              />
            ))}
          </>
        )}
        {!isResponse && isDatePoll && selectedSlots.length === 0 && (
          <>
            <GridItem xs={12}>
              <Divider />
            </GridItem>
            <GridItem>Select the dates when you are available</GridItem>
            {datePolls.map((x, i) => (
              <VoteSlot
                key={x.id}
                actualIndex={i}
                myAttributes={x.attributes}
                myId={x.id}
                eventId={myId}
                inviteeId={myInvites[0].id}
                isHost={isHost}
                maxVal={maxDatePoll}
              />
            ))}
          </>
        )}
        <GridItem xs={12}>
          <Divider />
        </GridItem>
        <GridItem>
          <GridPrime>
            <ImgInfo src="/Location4.svg" titleName="Location">
              {myAttributes.venue}
              {extraText}
            </ImgInfo>
            <ImgInfo src="/basket4.svg" titleName="Event Start">
              {myAttributes.isPending || selectedSlots.length === 0 ? (
                <>Yet to Decide</>
              ) : (
                <>
                  <Box>
                    {showDate(selectedSlots[0].attributes.startDateTime)}
                  </Box>
                  <Box>
                    {showTime(selectedSlots[0].attributes.startDateTime)}
                  </Box>
                </>
              )}
            </ImgInfo>
            <ImgInfo src="/basket4.svg" titleName="Event End">
              {myAttributes.isPending || selectedSlots.length === 0 ? (
                <>Yet to Decide</>
              ) : (
                <>
                  <Box>{showDate(selectedSlots[0].attributes.endDateTime)}</Box>
                  <Box>{showTime(selectedSlots[0].attributes.endDateTime)}</Box>
                </>
              )}
            </ImgInfo>
          </GridPrime>
        </GridItem>
        {isHost && (
          <GridItem>
            <GridPrime>
              <GridItem>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setIsBallot(true)}
                >
                  Create Poll
                </Button>
              </GridItem>
            </GridPrime>
          </GridItem>
        )}
        {/* Dont show when pending to response */}
        {!isResponse &&
          myInvites.length > 0 &&
          customBallots.map((x) => (
            <Fragment key={x[0]}>
              <GridItem xs={12}>
                <Divider />
              </GridItem>
              <GridItem>{x[0]}</GridItem>
              {x[1].map((y, i) => (
                <BallotSlot
                  key={y.id}
                  actualIndex={i}
                  myAttributes={y.attributes}
                  myId={y.id}
                  inviteeId={myInvites[0].id}
                  maxVal={Math.max(
                    ...x[1].map((z) => z.attributes.invitees.data.length)
                  )}
                />
              ))}
            </Fragment>
          ))}
        <GridItem xs={12}>
          <Divider />
        </GridItem>
        <GridItem>
          <Typography variant="h5">About Event</Typography>
          {myAttributes.details}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
          {extraText}
        </GridItem>
      </GridCol>
      <BaseModal
        isOpen={isBallot}
        handleClose={() => setIsBallot(false)}
        right="0"
        transform="translate(0, -50%)"
        noClose
      >
        <FormContainer
          FormProps={{ autoComplete: "off" }}
          onSuccess={async (actualData) => {
            const myDecis = [actualData.choiceA, actualData.choiceB];
            if (actualData.choiceC != null && actualData.choiceC.length > 0) {
              myDecis.push(actualData.choiceC);
            }
            if (actualData.choiceD != null && actualData.choiceD.length > 0) {
              myDecis.push(actualData.choiceD);
            }

            setIsLoading(true);
            try {
              for (const question of myDecis) {
                await postDecision({
                  data: {
                    question,
                    title: actualData.ballotTitle,
                    event: myId,
                    isManifold: actualData.isPlain !== true,
                  },
                });
              }

              // eslint-disable-next-line no-restricted-globals
              location.reload();
            } catch {
              setIsError(true);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          <Typography variant="h5">Create Custom Poll</Typography>
          <GridCol>
            <GridItem>
              Not sure what to eat or what to do on vacation? Create a group
              poll!
            </GridItem>
            <GridItem>
              <TextFieldElement
                name="ballotTitle"
                label="Poll Question"
                required
                variant="standard"
                fullWidth
              />
            </GridItem>
            <GridItem>
              <TextFieldElement
                name="choiceA"
                label="Choice A"
                required
                variant="standard"
                fullWidth
              />
            </GridItem>
            <GridItem>
              <TextFieldElement
                name="choiceB"
                label="Choice B"
                required
                variant="standard"
                fullWidth
              />
            </GridItem>
            <GridItem>
              <TextFieldElement
                name="choiceC"
                label="Choice C"
                variant="standard"
                fullWidth
              />
            </GridItem>
            <GridItem>
              <TextFieldElement
                name="choiceD"
                label="Choice D"
                variant="standard"
                fullWidth
              />
            </GridItem>
            <GridItem>
              <SwitchBool name="isPlain" label="One choice per person" />
            </GridItem>
            <GridItem>
              {!isLoading && (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Create Poll
                </Button>
              )}
              {isLoading && <LoadingPicture />}
            </GridItem>
          </GridCol>
        </FormContainer>
      </BaseModal>
    </BasicCard>
  );
};

export default memo(EventDetail);
