import React, { useEffect, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { getEvents } from "../../api/strapi";
import ErrorPage from "./ErrorPage";
import LoadingCard from "../shared/LoadingCard";
import { useLocalStorage } from "../../api/useLocalStorage";
import { eventList } from "../../util";

const FirstElement = () => {
  const [isPageError, setIsPageError] = useState(false);
  const [userData] = useLocalStorage("userData", null);
  const [eventKind] = useLocalStorage("eventKind", 1);
  const [, setTempReunions] = useLocalStorage("tempReunions", null);
  const navigateVal = useNavigate();

  useEffect(() => {
    const startFetch = async () => {
      try {
        const meetList = await getEvents(
          eventKind,
          userData.user.id,
          userData.user.email,
          setTempReunions
        );

        const meetId = meetList.length > 0 ? meetList[0].id : 0;
        navigateVal(`/${eventList}${meetId}`);
      } catch {
        setIsPageError(true);
      }
    };

    startFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPageError) {
    return <ErrorPage />;
  }

  return <LoadingCard />;
};

export default memo(FirstElement);
