import React, { memo } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import BasicCard from "./BasicCard";
import { a5, b5, a8, c7 } from "../../theme/metrics";

const BaseModal = ({
  children,
  isOpen,
  handleClose,
  // Lessen optional props
  right = "50%",
  transform = "translate(50%, -50%)",
  noClose = false,
}) => (
  <Modal
    open={isOpen}
    onClose={noClose ? undefined : handleClose}
    sx={{ backdropFilter: "blur(5px)" }}
  >
    <Box
      sx={{
        transform,
        right,
        top: "50%",
        position: "absolute",
        padding: { xs: a5, sm: b5 },
        width: { xs: "100%", sm: c7 },
        maxHeight: "100%",
        overflowY: "auto",
      }}
    >
      <BasicCard>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 0, right: 0, padding: a8 }}
        >
          <Close />
        </IconButton>
        {isOpen && children}
      </BasicCard>
    </Box>
  </Modal>
);

export default memo(BaseModal);
