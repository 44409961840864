import React, { memo } from "react";
import GridItem from "../shared/GridItem";
import { IconButton, Typography } from "@mui/material";
import GridPrime from "../shared/GridPrime";
import { ArrowBackIosNew } from "@mui/icons-material";

const BackTitle = ({ onClick, children }) => (
  <GridPrime>
    <GridItem>
      <IconButton onClick={onClick}>
        <ArrowBackIosNew />
      </IconButton>
    </GridItem>
    <GridItem>
      <Typography variant="h5">{children}</Typography>
    </GridItem>
  </GridPrime>
);

export default memo(BackTitle);
