import React, { useEffect, useState, memo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addInvitee } from "../../api/strapi";
import ErrorPage from "./ErrorPage";
import LoadingCard from "../shared/LoadingCard";
import { isInviteInvalid, noInviteMessage } from "../../util";
import { useAppContext } from "../../router/AppContext";

const ReceivePage = () => {
  const [isPageError, setIsPageError] = useState(false);
  const { setSnackMessage } = useAppContext();
  const [searchParams] = useSearchParams();
  const navigateVal = useNavigate();

  useEffect(() => {
    const startFetch = async () => {
      try {
        await addInvitee({
          payload: {
            eventId: searchParams.get("event_id"),
            inviteId: searchParams.get("invitation_id"),
          },
        });

        window.location.href = "/";
      } catch (errorCatch) {
        if (isInviteInvalid(errorCatch)) {
          setSnackMessage(noInviteMessage);
          navigateVal("/");
          return;
        }

        setIsPageError(true);
      }
    };

    startFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPageError) {
    return <ErrorPage />;
  }

  return <LoadingCard />;
};

export default memo(ReceivePage);
