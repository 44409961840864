import React, { memo } from "react";
import { TextFieldElement } from "react-hook-form-mui";
import GridItem from "../shared/GridItem";
const AlterEvent = () => (
  <>
    <GridItem>
      <TextFieldElement
        name="title"
        label="Event title"
        required
        variant="standard"
        fullWidth
      />
    </GridItem>
    <GridItem>
      <TextFieldElement
        name="venue"
        label="Location"
        required
        variant="standard"
        fullWidth
      />
    </GridItem>
    <GridItem>
      <TextFieldElement
        name="details"
        label="Details"
        required
        multiline
        rows={5}
        fullWidth
      />
    </GridItem>
  </>
);

export default memo(AlterEvent);
