import React, { memo } from "react";
import { Box } from "@mui/material";
import { b3, b5 } from "../../theme/metrics";

const BasicCard = ({
  children,
  // Lessen optional props
  isSelected = false,
  id = "",
  isRed = false,
}) => (
  <Box
    id={id}
    sx={{
      padding: b5,
      borderRadius: b3,
      border: "1px solid",
      borderColor: isRed ? "red" : "secondary.main",
      backgroundColor: isSelected ? "secondary.main" : "background.paper",
      position: "relative",
    }}
  >
    {children}
  </Box>
);

export default memo(BasicCard);
