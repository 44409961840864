// https://github.com/dohomi/react-hook-form-mui/blob/37f2b6755886ca9f9c5ab7066bb0f501bbae7c10/packages/rhf-mui/src/SwitchElement.tsx

import React, { memo } from "react";
import { Controller } from "react-hook-form-mui";
import {
  FormHelperText,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import GridItem from "../shared/GridItem";
import { b1 } from "../../theme/metrics";

const ToogleWeek = ({ name }) => {
  const validation = { required: "This field is required" };

  return (
    <Controller
      name={name}
      rules={validation}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <GridItem>
          <FormLabel error={invalid} sx={{ fontSize: b1 }}>
            Select schedule *
          </FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={value}
            onChange={(_, y) => onChange(y)}
          >
            <ToggleButton value={0}>Mon</ToggleButton>
            <ToggleButton value={1}>Tue</ToggleButton>
            <ToggleButton value={2}>Wed</ToggleButton>
            <ToggleButton value={3}>Thu</ToggleButton>
            <ToggleButton value={4}>Fri</ToggleButton>
            <ToggleButton value={5}>Sat</ToggleButton>
            <ToggleButton value={6}>Sun</ToggleButton>
          </ToggleButtonGroup>
          {error != null && (
            <FormHelperText error={invalid}>{error.message}</FormHelperText>
          )}
        </GridItem>
      )}
    />
  );
};
export default memo(ToogleWeek);
