import React, { memo } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { b5 } from "../../theme/metrics";

const GoHome = ({ children }) => (
  <Box sx={{ padding: b5 }}>
    {children} <Link to="/">Go to Home</Link>
  </Box>
);

export default memo(GoHome);
