import React, { useEffect, useState, memo } from "react";
import EventList from "../main/EventList";
import ReunionDetail from "../detail/ReunionDetail";
import { useParams } from "react-router-dom";
import { Box, Slide } from "@mui/material";

// TODO is this component for performance?
const ShortLayout = ({ isList, reunionArray, setTribeList }) => {
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    setIsInit(true);
  }, []);

  const { listIndex } = useParams();
  return (
    <>
      {isList && (
        <Slide direction="right" in={true} appear={isInit}>
          <Box>
            <EventList reunionArray={reunionArray} />
          </Box>
        </Slide>
      )}
      {!isList && (
        <Box sx={{ overflowX: "hidden" }}>
          <Slide direction="left" in={true} appear={isInit}>
            <Box>
              <ReunionDetail
                theEvent={
                  reunionArray.filter((x) => {
                    let y = {};
                    y = x;
                    return y.id.toString() === listIndex;
                  })[0]
                }
                setTribeList={setTribeList}
              />
            </Box>
          </Slide>
        </Box>
      )}
    </>
  );
};

export default memo(ShortLayout);
