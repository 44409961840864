import React, { memo, useEffect } from "react";
import { Box } from "@mui/material";
import { b5 } from "../../theme/metrics";
import LandingGrid from "./LandingGrid";
import { useSearchParams } from "react-router-dom";
import { useLocalStorage } from "../../api/useLocalStorage";

const LandingPage = () => {
  const [searchParams] = useSearchParams();
  const [, setTempLink] = useLocalStorage("tempLink", null);
  const [, setTempInvitee] = useLocalStorage("tempInvitee", null);

  useEffect(() => {
    localStorage.clear();
    const myLink = searchParams.get("link");
    if (myLink != null) {
      setTempLink(myLink);
    }
    if (searchParams.get("invitation_id") != null) {
      setTempInvitee({
        payload: {
          eventId: searchParams.get("event_id"),
          inviteId: searchParams.get("invitation_id"),
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ padding: b5 }}>
      <LandingGrid
        sx={{ display: { xs: "flex", md: "none" } }}
        styleExtra={{ textAlign: "center" }}
      />
      <LandingGrid
        sx={{ display: { xs: "none", md: "flex" } }}
        styleExtra={{}}
      />
    </Box>
  );
};

export default memo(LandingPage);
