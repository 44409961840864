import React, { useEffect, useState, memo } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocalStorage } from "../../api/useLocalStorage";
import ErrorPage from "./ErrorPage";
import LoadingCard from "../shared/LoadingCard";
import { passwordlessLogin } from "../../api/auth";

// TODO this is part of passwordless logic
const TokenLimitedPage = () => {
  const [searchParams] = useSearchParams();
  const [isPageError, setIsPageError] = useState(false);
  const [, setUserData] = useLocalStorage("userData", null);

  useEffect(() => {
    const startFetch = async () => {
      try {
        const userData = await passwordlessLogin(
          searchParams.get("loginToken")
        );
        setUserData(userData.data);
        window.location.href = "/";
      } catch {
        setIsPageError(true);
      }
    };

    startFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPageError) {
    return <ErrorPage />;
  }

  return <LoadingCard />;
};

export default memo(TokenLimitedPage);
