import React, { memo, useRef, useEffect } from "react";
import BasicCard from "../shared/BasicCard";
import EventItem from "./EventItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GridItem from "../shared/GridItem";
import { Box } from "@mui/material";
import { eventDetail, eventList, isPend, scrollStart } from "../../util";
import { useLocalStorage } from "../../api/useLocalStorage";

const myDeleted = "Canceled event";

const EventTop = ({ myAttributes, reunionIndex }) => {
  const [userData] = useLocalStorage("userData", null);
  const navigateVal = useNavigate();
  const { pathname } = useLocation();
  const { listIndex } = useParams();
  const myRef = useRef(null);
  const isList = pathname.indexOf(eventList) >= 0;

  useEffect(() => {
    if (isList && myRef.current != null && listIndex === reunionIndex) {
      // Scroll list item
      scrollStart(myRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <GridItem onClick={() => navigateVal(`/${eventDetail}${reunionIndex}`)}>
      <Box ref={myRef}>
        <BasicCard
          isSelected={listIndex === reunionIndex}
          isRed={isPend(myAttributes, userData.user.email)}
        >
          {!myAttributes.isDeleted && <EventItem myAttributes={myAttributes} />}
          {myAttributes.isDeleted && <>{myDeleted}</>}
        </BasicCard>
      </Box>
    </GridItem>
  );
};

export default memo(EventTop);
