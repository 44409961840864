import React, { memo } from "react";
import { Controller } from "react-hook-form-mui";
import DatePicker from "react-datepicker";
import { TextField } from "@mui/material";

const DateOnePicker = ({ required, name, minDate, maxDate, ...rest }) => {
  const validation = { required: "This field is required" };

  return (
    <Controller
      name={name}
      rules={validation}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, error },
      }) => (
        <DatePicker
          onChange={(actualDate) => {
            onChange(actualDate);
          }}
          selected={value}
          minDate={minDate}
          maxDate={maxDate}
          customInput={
            <TextField
              {...rest}
              name={name}
              onBlur={onBlur}
              required={required}
              error={invalid}
              helperText={error ? error.message : rest.helperText}
            />
          }
        />
      )}
    />
  );
};
export default memo(DateOnePicker);
