import React, { memo } from "react";
import { Button, Typography } from "@mui/material";
import GridItem from "../shared/GridItem";
import LoadingPicture from "../shared/LoadingPicture";
import GridCol from "../shared/GridCol";
import GridPrime from "../shared/GridPrime";

const YesNo = ({ children, alertTitle, handleYes, handleNo, isLoading }) => {
  return (
    <>
      <Typography variant="h5">{alertTitle}</Typography>
      <GridCol>
        <GridItem>{children}</GridItem>
        <GridItem sx={{ textAlign: "center" }}>
          {!isLoading && (
            <GridPrime justifyContent="center">
              <GridItem>
                <Button variant="contained" size="large" onClick={handleYes}>
                  Yes
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  variant="outlined"
                  color="error"
                  size="large"
                  onClick={handleNo}
                >
                  No
                </Button>
              </GridItem>
            </GridPrime>
          )}
          {isLoading && <LoadingPicture />}
        </GridItem>
      </GridCol>
    </>
  );
};

export default memo(YesNo);
