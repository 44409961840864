import React, { memo } from "react";
import { Grid } from "@mui/material";

const GridPrime = ({ children, ...myProps }) => (
  <Grid container spacing={2} alignItems="center" {...myProps}>
    {children}
  </Grid>
);

export default memo(GridPrime);
