import React, { memo, useState } from "react";
import GridItem from "../shared/GridItem";
import WidthChart from "../main/WidthChart";
import { Checkbox } from "@mui/material";
import { Done, DoneOutline } from "@mui/icons-material";
import LoadingPicture from "../shared/LoadingPicture";
import { useLocalStorage } from "../../api/useLocalStorage";
import { useAppContext } from "../../router/AppContext";
import { b6 } from "../../theme/metrics";
import { getDecisions, putInvitee } from "../../api/strapi";
import GridPrime from "../shared/GridPrime";

const pairBase = 2;
const BallotSlot = ({ myId, myAttributes, actualIndex, inviteeId, maxVal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData] = useLocalStorage("userData", null);
  const { setIsError } = useAppContext();
  const isChecked =
    myAttributes.invitees.data.filter(
      (x) => x.attributes.email === userData.user.email
    ).length > 0;

  return (
    <GridItem
      className="backgroundItem"
      sx={{
        backgroundColor:
          actualIndex % pairBase === 0 ? "secondary.main" : undefined,
      }}
    >
      <GridPrime justifyContent="space-between" wrap="nowrap">
        <GridItem>
          <GridPrime>
            <GridItem>
              <Checkbox
                size="small"
                icon={<DoneOutline />}
                checkedIcon={<Done sx={{ fontSize: b6 }} />}
                onChange={async () => {
                  if (isLoading) {
                    return;
                  }
                  setIsLoading(true);
                  try {
                    const myResponse = await getDecisions(inviteeId);
                    const previousDecis = myResponse.data.data;

                    let decisions;
                    if (isChecked) {
                      decisions = previousDecis
                        .map((x) => x.id)
                        .filter((x) => x !== myId);
                    } else {
                      decisions = [...previousDecis.map((x) => x.id), myId];
                    }

                    await putInvitee(inviteeId, {
                      data: {
                        decisions,
                      },
                    });
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                  } catch {
                    setIsError(true);
                  } finally {
                    setIsLoading(false);
                  }
                }}
                checked={isChecked || isLoading}
              />
            </GridItem>
            {!isLoading && <GridItem>{myAttributes.question}</GridItem>}
            {isLoading && (
              <GridItem>
                <LoadingPicture />
              </GridItem>
            )}
          </GridPrime>
        </GridItem>
        <WidthChart
          dataVal={myAttributes.invitees.data.length}
          maxVal={maxVal}
          tinyWidth={90}
          bigWidth={360}
        />
      </GridPrime>
    </GridItem>
  );
};

export default memo(BallotSlot);
