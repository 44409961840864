import React, { memo } from "react";
import { Avatar, Box } from "@mui/material";
import { b2 } from "../../theme/metrics";
import { extraText, getDub } from "../../util";
import GridItem from "../shared/GridItem";
import TextCard from "../shared/TextCard";
import GridPrime from "../shared/GridPrime";

const PersonInfo = ({ myAttributes, avatarWidth, userUnique, isPending }) => (
  <GridItem>
    <GridPrime justifyContent="space-between" wrap="nowrap">
      <GridItem>
        <GridPrime wrap="nowrap">
          <GridItem>
            <Avatar
              alt={getDub(myAttributes)}
              src={myAttributes.picture}
              sx={{ width: avatarWidth, height: avatarWidth }}
            />
          </GridItem>
          <GridItem>
            <Box sx={{ color: "primary.main", fontSize: b2 }}>
              Host{extraText}
            </Box>
            <Box>
              {getDub(myAttributes)}
              {extraText}
            </Box>
          </GridItem>
        </GridPrime>
      </GridItem>
      <GridItem>
        <GridPrime>
          {myAttributes.email === userUnique && (
            <GridItem>
              <TextCard backgroundColor="lightgray" color={undefined}>
                Hosted By Me
              </TextCard>
            </GridItem>
          )}
          {isPending === true && (
            <GridItem>
              <TextCard backgroundColor="primary.main" color="white">
                Pending
              </TextCard>
            </GridItem>
          )}
        </GridPrime>
      </GridItem>
    </GridPrime>
  </GridItem>
);

export default memo(PersonInfo);
