import React, { useEffect, memo } from "react";
import GridItem from "../shared/GridItem";
import {
  TextFieldElement,
  useWatch,
  useFormContext,
} from "react-hook-form-mui";
import { InputAdornment } from "@mui/material";

const PhoneInvite = () => {
  const { email } = useWatch();
  const { clearErrors } = useFormContext();
  useEffect(() => {
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <GridItem>
      <TextFieldElement
        name="phone"
        label="Phone"
        variant="standard"
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start">+1</InputAdornment>,
        }}
        validation={{
          validate: {
            checkEmail: (v) =>
              (v != null && v.length > 0) ||
              (email != null && email.length > 0) ||
              "Email or phone is required",
          },
        }}
      />
    </GridItem>
  );
};
export default memo(PhoneInvite);
