import React, { memo } from "react";

const LoadingPicture = () => (
  <img
    alt="img"
    src="https://icons8.com/preloaders/preloaders/486/4%20Fading%20blocks.gif"
    width="40px"
  />
);

export default memo(LoadingPicture);
