import React, { useEffect, useState, memo } from "react";
import { SelectElement, useFormContext, useWatch } from "react-hook-form-mui";
import { differenceInDays, differenceInHours } from "date-fns";
import { suffixIso } from "../../util";
import GridItem from "../shared/GridItem";

const defaultHours = 0;
const defaultDays = 0;
const dayText = "2000-01-01";

const SelectCreate = ({
  // Lessen optional props
  isDays = false,
}) => {
  const [optionCount, setOptionCount] = useState(defaultHours);
  const [optionType, setOptionType] = useState("hour");
  const [typePlural, setTypePlural] = useState("hours");
  const watchValues = useWatch();
  const { setValue } = useFormContext();

  useEffect(() => {
    // Clear value of required-field when these change: dateRange, startTime, endTime
    setValue("eventDuration", undefined);

    const valueLength = 10;
    const charFlag = "^";
    const isFilled = /^.+\^.+$/.test(watchValues.dateRange);
    const dateStart = isFilled
      ? watchValues.dateRange.split(charFlag)[0].substring(0, valueLength)
      : dayText;
    const dateEnd = isFilled
      ? watchValues.dateRange.split(charFlag)[1].substring(0, valueLength)
      : dayText;
    if (isDays) {
      setOptionType("day");
      setTypePlural("days");

      if (!isFilled) {
        setOptionCount(defaultDays);
        return;
      }

      setOptionCount(
        differenceInDays(
          new Date(dateEnd + suffixIso),
          new Date(dateStart + suffixIso)
        ) + 1
      );
    } else {
      setOptionType("hour");
      setTypePlural("hours");

      const { startTime, endTime } = watchValues;

      if (
        startTime == null ||
        startTime.length === 0 ||
        endTime == null ||
        endTime.length === 0
      ) {
        setOptionCount(defaultHours);
        return;
      }

      const minuteLength = 6;
      const timeHours = differenceInHours(
        new Date(`${dateEnd}T${endTime}${suffixIso.slice(minuteLength)}`),
        new Date(`${dateStart}T${startTime}${suffixIso.slice(minuteLength)}`)
      );

      setOptionCount(timeHours);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchValues.dateRange, watchValues.startTime, watchValues.endTime]);

  return (
    <GridItem>
      <SelectElement
        name="eventDuration"
        label={`How many ${typePlural.toLowerCase()} is your event?`}
        required
        variant="standard"
        fullWidth
        options={[...Array(optionCount > 0 ? optionCount : 0).keys()].map(
          (x) => ({
            id: x + 1,
            label: `${x + 1} ${x + 1 > 1 ? typePlural : optionType}`,
          })
        )}
      />
    </GridItem>
  );
};

export default memo(SelectCreate);
