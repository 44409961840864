import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import MainRouter from "./router/MainRouter";
import { BrowserRouter } from "react-router-dom";
import materialTheme from "./theme/material";
import PublicRouter from "./router/PublicRouter";
import { useLocalStorage } from "./api/useLocalStorage";
import AppContext from "./router/AppContext";
import { CssBaseline, Snackbar } from "@mui/material";

const App = () => {
  const [isError, setIsError] = useState(false);
  const [matchInfo, setMatchInfo] = useState();
  const [userData] = useLocalStorage("userData", null);
  const [reunionData, setMeetData] = useLocalStorage("reunionData", null);
  const [snackMessage, setSnackMessage] = useLocalStorage("snackMessage", null);
  const [darkMode, setDarkMode] = useLocalStorage("darkMode", false);

  useEffect(() => {
    console.log("inizzz");
    //setDarkMode(true);
  }, []);

  return (
    <ThemeProvider theme={materialTheme(darkMode ? "dark" : "light")}>
      <CssBaseline />
      <AppContext.Provider
        value={{
          isError,
          setIsError,
          matchInfo,
          setMatchInfo,
          reunionData,
          setMeetData,
          snackMessage,
          setSnackMessage,
        }}
      >
        <BrowserRouter>
          {userData == null && <PublicRouter />}
          {userData != null && <MainRouter />}
        </BrowserRouter>
        <Snackbar
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackMessage != null}
          onClose={() => setSnackMessage(null)}
          message={snackMessage}
          key={snackMessage}
        />
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default App;
