import React, { memo, useState } from "react";
import { Button } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import LoadingPicture from "../shared/LoadingPicture";
import { generateGuid } from "../../util";
import { putEvent } from "../../api/strapi";
import { Link } from "@mui/icons-material";
import { useAppContext } from "../../router/AppContext";
import GridItem from "../shared/GridItem";

const CopyLink = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setIsError, reunionData, setSnackMessage } = useAppContext();

  return (
    <GridItem alignSelf="end">
      {!isLoading && (
        <Button
          variant="contained"
          size="large"
          startIcon={<Link />}
          onClick={async () => {
            // The `try` must be followed by `await` execution, so execute external method before `try`
            const newLink = generateGuid();
            let urlLink = reunionData.attributes.urlLink;
            setIsLoading(true);
            try {
              if (urlLink == null) {
                urlLink = newLink;
                await putEvent(reunionData.id, {
                  data: {
                    // This is a token that should be shared only between invitees
                    urlLink,
                  },
                });
              }

              navigator.clipboard.writeText(
                `${process.env.REACT_APP_FE_PATH}link?link=${urlLink}`
              );
              setSnackMessage("Link copied");
            } catch {
              setIsError(true);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          Copy Link
        </Button>
      )}
      {isLoading && <LoadingPicture />}
    </GridItem>
  );
};

export default memo(CopyLink);
