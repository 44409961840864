import React, { Fragment, memo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import { FormContainer, SelectElement } from "react-hook-form-mui";
import "react-datepicker/dist/react-datepicker.css";
import {
  modMeeting,
  postDecision,
  postEvent,
  putEvent,
  putSlot,
} from "../../api/strapi";
import DateOnePicker from "./DateOnePicker";
import DateRangePicker from "./DateRangePicker";
import { addYears, format, differenceInSeconds, addSeconds } from "date-fns";
import LoadingPicture from "../shared/LoadingPicture";
import SelectCreate from "./SelectCreate";
import { useLocalStorage } from "../../api/useLocalStorage";
import GridItem from "../shared/GridItem";
import { useAppContext } from "../../router/AppContext";
import { suffixMili, toUtc } from "../../util";
import TimeCreate from "./TimeCreate";
import ToogleWeek from "./ToogleWeek";
import SwitchBool from "./SwitchBool";
import { b1, c4 } from "../../theme/metrics";
import GridCol from "../shared/GridCol";
import GridPrime from "../shared/GridPrime";
import { produce } from "immer";
import BackTitle from "../main/BackTitle";
import BaseModal from "../shared/BaseModal";
import YesNo from "../main/YesNo";
import AlterEvent from "./AlterEvent";

// Test no-auth and auth | Test with no-host and host | Test isResponse true or false
// TODO
// Send comment: Old events will throw an error when trying to edit because googleCalendarApi->eventId was not saved in strapi so undefined linked eventId
// After ConfirmSlot: Google calendar entry not created when user accepts event
// Edit event
//   Date picker limit minDate
// "Xyz event was updated" red text on top of user avatar
//   This way prevents interrupting the user input-output. Autorefresh, when is not annoying, it is imperceptible.
// Testim
// SingleDay connect earlyTime, connect lateTime
// MultiDay connect ToogleWeek
// Login existing user with passwordlessLogin. Dont signup new user with passwordlessLogin because calendar permissions
// Dates Poll
//   validate max number dates
// Create custom poll
//   When isManifold is false, one choice per person
//   validate previous poll title
//   validate equality of choices
// delete timeZone property
// Not valid welcome image, BE commit: 3f4ba408
// Add Invite
//   validate at least one invitation
// Refresh user auth token
// Zero events: "No event found" https://i.imgur.com/cPEsvm2.jpg maybe add fullscreen video in AW folder
const wizMulti = 2;
const wizPoll = 3;
const wizVote = 4;
const wizEdit = 5;
const dayHours = 24;
const valueLength = 10;
const minPoll = 2;
const emptyArray = [];

const CreateEvent = ({ headerNull }) => {
  const { setIsError, setMeetData, matchInfo } = useAppContext();
  const [wizStep, setWizStep] = useState(matchInfo != null ? wizEdit : 0);
  const [headerData, setHeaderData] = useState(headerNull);
  const [pollList, setPollList] = useState(emptyArray);
  const [isAlert, setIsAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData] = useLocalStorage("userData", null);
  const matchSlot =
    matchInfo != null
      ? matchInfo.attributes.slots.data.filter(
          (x) => x.attributes.isSelected === true
        )[0]
      : undefined;
  const dateEdit = matchSlot != null;

  const handleZero = async (actualData) => {
    let theStep;
    if (actualData.isPoll) {
      // When going to step zero, and return, show the user the pollList was saved
      theStep = pollList.length > 0 ? wizPoll : wizVote;
    } else if (actualData.isMulti) {
      theStep = wizMulti;
    } else {
      theStep = 1;
    }

    setHeaderData(actualData);
    setWizStep(theStep);
  };

  const backEvent = () => {
    setWizStep(0);
  };

  const handleCreate = async (actualData, allDay) => {
    const { dateRange, startTime, endTime, eventDuration } = actualData;
    const dateValues = dateRange.split("^");
    const oneDate = dateValues[0].substring(0, valueLength);
    const secondDate = dateValues[1].substring(0, valueLength);

    const startInit = toUtc(new Date(`${oneDate}T${startTime}${suffixMili}`));
    const startDate = startInit.toISOString();

    const endInit = toUtc(new Date(`${secondDate}T${endTime}${suffixMili}`));
    const endDate = endInit.toISOString();

    const dataInfo = {
      data: {
        details: headerData.details,
        endDateRange: endDate.substring(0, valueLength),
        endTimeRange: endDate.slice(valueLength + 1, -1),
        isPending: true,
        startDateRange: startDate.substring(0, valueLength),
        startTimeRange: startDate.slice(valueLength + 1, -1),
        title: headerData.title,
        venue: headerData.venue,
        host: userData.user.id,
        allDay,
        //anyTime is not used
        anyTime: false,
        eventDuration,
        timeZone: 0,
      },
    };

    setIsLoading(true);
    try {
      const newInfo = await postEvent(dataInfo);
      const myEvent = newInfo.data.data;
      setMeetData(myEvent);
      window.location.href = "/";
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (actualData) => {
    const { startDate, startTime, title, venue, details } = actualData;

    let startDateTime;
    let endDateTime;
    if (dateEdit) {
      const oneDate = startDate.toISOString().substring(0, valueLength);
      startDateTime = toUtc(new Date(`${oneDate}T${startTime}${suffixMili}`));

      const initialDate = new Date(matchSlot.attributes.startDateTime);
      const finalDate = new Date(matchSlot.attributes.endDateTime);
      const timeDiff = differenceInSeconds(finalDate, initialDate);
      endDateTime = addSeconds(startDateTime, timeDiff);
    }
    setIsLoading(true);
    try {
      await putEvent(matchInfo.id, {
        data: {
          title,
          venue,
          details,
        },
      });

      if (dateEdit) {
        await putSlot(matchSlot.id, {
          data: {
            startDateTime,
            endDateTime,
          },
        });

        await modMeeting({
          eventId: matchInfo.id,
          googleEvent: {
            summary: title,
            location: venue,
            description: details,
            start: {
              dateTime: startDateTime,
            },
            end: {
              dateTime: endDateTime,
            },
          },
        });
      }

      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBallots = async () => {
    const dataInfo = {
      data: {
        details: headerData.details,
        title: headerData.title,
        venue: headerData.venue,
        host: userData.user.id,
        timeZone: 0,
        algoExecuted: true,
        isPending: false,
      },
    };

    setIsLoading(true);
    try {
      const newInfo = await postEvent(dataInfo);
      const myEvent = newInfo.data.data;
      const event = myEvent.id;

      for (const x of pollList) {
        await postDecision({
          data: {
            title: "DateVotePoll",
            startDateTime: x.startDate,
            durationHours: x.durationHours,
            event,
          },
        });
      }

      setMeetData(myEvent);
      window.location.href = "/";
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVote = (actualData) => {
    const startDate = new Date(
      actualData.startDate.toISOString().substring(0, valueLength + 1) +
        actualData.startTime +
        suffixMili
    );
    setPollList(
      produce((myPolls) => {
        myPolls.push({ ...actualData, startDate });
      })
    );
    setWizStep(wizPoll);
  };

  return (
    <>
      {wizStep === 0 && (
        <FormContainer
          FormProps={{ autoComplete: "off" }}
          defaultValues={{
            title: headerData != null ? headerData.title : undefined,
            venue: headerData != null ? headerData.venue : undefined,
            details: headerData != null ? headerData.details : undefined,
            isPoll: headerData != null ? headerData.isPoll : true,
            isMulti: headerData != null ? headerData.isMulti : undefined,
          }}
          onSuccess={handleZero}
        >
          <Typography variant="h5">Create Event</Typography>
          <GridCol>
            <AlterEvent />
            <GridItem>
              <SwitchBool name="isMulti" label="Multi day event" />
            </GridItem>
            <GridItem>
              <SwitchBool name="isPoll" label="Create poll" />
            </GridItem>
            <GridItem>
              <Button type="submit" variant="contained" size="large" fullWidth>
                Next
              </Button>
            </GridItem>
          </GridCol>
        </FormContainer>
      )}
      {wizStep === wizEdit && (
        <FormContainer
          FormProps={{ autoComplete: "off" }}
          defaultValues={{
            startDate: dateEdit
              ? new Date(matchSlot.attributes.startDateTime)
              : undefined,
            startTime: dateEdit
              ? format(new Date(matchSlot.attributes.startDateTime), "hh:mm")
              : undefined,
            title: matchInfo.attributes.title,
            venue: matchInfo.attributes.venue,
            details: matchInfo.attributes.details,
          }}
          onSuccess={handleEdit}
        >
          <Typography variant="h5">Edit Event</Typography>
          <GridCol>
            {dateEdit && (
              <>
                <GridItem>
                  <DateOnePicker
                    name="startDate"
                    label="Date *"
                    required
                    variant="standard"
                    fullWidth
                    minDate={addYears(new Date(), -1)}
                    maxDate={addYears(new Date(), 1)}
                  />
                </GridItem>
                <GridItem>
                  <Grid container>
                    <GridItem>
                      <TimeCreate name="startTime" label="Time" />
                    </GridItem>
                  </Grid>
                </GridItem>
              </>
            )}
            <AlterEvent />
            <GridItem>
              {!isLoading && (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Update
                </Button>
              )}
              {isLoading && <LoadingPicture />}
            </GridItem>

            <GridItem>
              <Button
                variant="outlined"
                color="error"
                size="large"
                fullWidth
                onClick={() => setIsAlert(true)}
              >
                Cancel Event
              </Button>
            </GridItem>
          </GridCol>
        </FormContainer>
      )}
      {wizStep === 1 && (
        <FormContainer
          FormProps={{ autoComplete: "off" }}
          onSuccess={(x) => handleCreate(x, false)}
        >
          <BackTitle onClick={backEvent}>Create Single Day Event</BackTitle>
          <GridCol>
            <GridItem>
              <DateRangePicker
                name="dateRange"
                label="Select a date range to sync with your group *"
                required
                variant="standard"
                fullWidth
                minDate={new Date()}
                maxDate={addYears(new Date(), 1)}
              />
            </GridItem>
            <GridItem>
              <FormLabel sx={{ fontSize: b1 }}>
                Select a time range for your event
              </FormLabel>
              <GridPrime alignItems="end">
                <GridItem>
                  <TimeCreate name="startTime" label="Start time" />
                </GridItem>
                <GridItem>-</GridItem>
                <GridItem>
                  <TimeCreate name="endTime" label="End time" />
                </GridItem>
              </GridPrime>
            </GridItem>
            <GridItem>
              <TimeCreate
                name="earlyTime"
                label="How early can the event start?"
              />
            </GridItem>
            <GridItem>
              <TimeCreate
                name="lateTime"
                label="How late can the event start?"
              />
            </GridItem>
            <SelectCreate />
            <GridItem>
              {!isLoading && (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Create Event
                </Button>
              )}
              {isLoading && <LoadingPicture />}
            </GridItem>
          </GridCol>
        </FormContainer>
      )}
      {wizStep === wizMulti && (
        <FormContainer
          FormProps={{ autoComplete: "off" }}
          onSuccess={(x) => handleCreate(x, true)}
        >
          <BackTitle onClick={backEvent}>Create Multi Day Event</BackTitle>
          <GridCol>
            <GridItem>
              <DateRangePicker
                name="dateRange"
                label="Select a date range to sync with your group *"
                required
                variant="standard"
                fullWidth
                minDate={new Date()}
                maxDate={addYears(new Date(), 1)}
              />
            </GridItem>
            <GridItem>
              <GridPrime alignItems="end">
                <GridItem>
                  <TimeCreate name="startTime" label="Start time" />
                </GridItem>
                <GridItem>-</GridItem>
                <GridItem>
                  <TimeCreate name="endTime" label="End time" />
                </GridItem>
              </GridPrime>
            </GridItem>
            <SelectCreate isDays />
            <ToogleWeek name="weekDays" />
            <GridItem>
              {!isLoading && (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Create Event
                </Button>
              )}
              {isLoading && <LoadingPicture />}
            </GridItem>
          </GridCol>
        </FormContainer>
      )}
      {wizStep === wizPoll && (
        <>
          <BackTitle onClick={backEvent}>Create Poll</BackTitle>
          <GridCol>
            <GridItem>Select a few dates to ask your group about</GridItem>
            {pollList.map((x, i) => (
              <Fragment key={`g_${i.toString()}`}>
                {i > 0 && (
                  <GridItem xs={12}>
                    <Divider />
                  </GridItem>
                )}
                <GridItem>
                  <Box>{format(x.startDate, "EEEE, MMMM d")}</Box>
                  <Box>
                    {format(x.startDate, "h:mm a")} for {x.durationHours}{" "}
                    {x.durationHours > 1 ? "hours" : "hour"}
                  </Box>
                </GridItem>
              </Fragment>
            ))}
            <GridItem>
              <Button
                variant="contained"
                size="large"
                onClick={() => setWizStep(wizVote)}
              >
                Add Date
              </Button>
            </GridItem>
            <GridItem>
              {!isLoading && (
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={pollList.length < minPoll}
                  onClick={handleBallots}
                >
                  Create Poll
                </Button>
              )}
              {isLoading && <LoadingPicture />}
            </GridItem>
          </GridCol>
        </>
      )}
      {wizStep === wizVote && (
        <FormContainer
          FormProps={{ autoComplete: "off" }}
          onSuccess={handleVote}
        >
          <BackTitle onClick={() => setWizStep(wizPoll)}>
            Add Poll Date
          </BackTitle>
          <GridCol
            // Prevent modal scrollbar
            sx={{ minHeight: c4 }}
          >
            <GridItem>
              <DateOnePicker
                name="startDate"
                label="Date *"
                required
                variant="standard"
                fullWidth
                minDate={new Date()}
                maxDate={addYears(new Date(), 1)}
              />
            </GridItem>
            <GridItem>
              <Grid container>
                <GridItem>
                  <TimeCreate name="startTime" label="Start time?" />
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem>
              <SelectElement
                name="durationHours"
                label={"Duration?"}
                required
                variant="standard"
                fullWidth
                options={[...Array(dayHours).keys()].map((x) => ({
                  id: x + 1,
                  label: `${x + 1} ${x + 1 > 1 ? "hours" : "hour"}`,
                }))}
              />
            </GridItem>
            <GridItem>
              <Button type="submit" variant="contained" size="large" fullWidth>
                Add Date
              </Button>
            </GridItem>
          </GridCol>
        </FormContainer>
      )}
      <BaseModal isOpen={isAlert} handleClose={() => setIsAlert(false)}>
        <YesNo
          alertTitle="Cancel Event"
          handleYes={async () => {
            setIsLoading(true);
            try {
              await putEvent(matchInfo.id, {
                data: {
                  isDeleted: true,
                },
              });

              window.location.href = "/";
            } catch {
              setIsError(true);
            } finally {
              setIsLoading(false);
            }
          }}
          handleNo={() => setIsAlert(false)}
          isLoading={isLoading}
        >
          Are you sure you want to cancel the event?
        </YesNo>
      </BaseModal>
    </>
  );
};

export default memo(CreateEvent);
