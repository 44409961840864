import React, { memo } from "react";
import { Box, Divider } from "@mui/material";
import PersonInfo from "../detail/PersonInfo";
import { b2, b4, b7 } from "../../theme/metrics";
import { extraNumber, extraText, showDate, showTime } from "../../util";
import GridItem from "../shared/GridItem";
import { useLocalStorage } from "../../api/useLocalStorage";
import GridPrime from "../shared/GridPrime";
import GridCol from "../shared/GridCol";

const EventItem = ({ myAttributes }) => {
  const [userData] = useLocalStorage("userData", null);
  const inviteesNumber = myAttributes.invitees.data.filter(
    (x) => !x.attributes.isHost
  ).length;
  const selectedSlots = myAttributes.slots.data.filter(
    (x) => x.attributes.isSelected === true
  );

  return (
    <GridCol>
      <GridItem>
        <GridPrime
          justifyContent="space-between"
          wrap="nowrap"
          alignItems="top"
        >
          <GridItem>
            <Box sx={{ fontSize: b4, fontWeight: "bold" }}>
              {myAttributes.title}
              {extraText}
            </Box>
            <Box sx={{ fontSize: b2 }}>
              {myAttributes.isPending === true || selectedSlots.length === 0
                ? "Yet to Decide"
                : `${showDate(
                    selectedSlots[0].attributes.startDateTime
                  )} • ${showTime(selectedSlots[0].attributes.startDateTime)}`}
              {extraText}
            </Box>
          </GridItem>
          <GridItem sx={{ fontWeight: "bold" }}>
            {extraNumber + inviteesNumber}{" "}
            {inviteesNumber === 1 ? "invitee" : "invitees"}
          </GridItem>
        </GridPrime>
      </GridItem>
      <GridItem xs={12}>
        <Divider />
      </GridItem>
      <PersonInfo
        avatarWidth={b7}
        myAttributes={myAttributes.host.data.attributes}
        userUnique={userData.user.email}
        isPending={myAttributes.isPending}
      />
    </GridCol>
  );
};

export default memo(EventItem);
