// https://github.com/dohomi/react-hook-form-mui/blob/37f2b6755886ca9f9c5ab7066bb0f501bbae7c10/packages/rhf-mui/src/TextFieldElement.tsx

import React, { useState, memo } from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

const DateRangePicker = ({ required, name, minDate, maxDate, ...rest }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const validation = {
    pattern: {
      value: /^.+\^.+$/,
      message: "Please enter a valid date range",
    },
  };

  if (required === true) {
    validation.required = "This field is required";
  }

  return (
    <Controller
      name={name}
      rules={validation}
      render={({
        field: { onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <DatePicker
          onChange={(actualDates) => {
            const [startRange, endRange] = actualDates;
            onChange(
              `${startRange != null ? startRange.toISOString() : ""}^${
                endRange != null ? endRange.toISOString() : ""
              }`
            );
            // @ts-ignore
            setStartDate(startRange);
            // @ts-ignore
            setEndDate(endRange);
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          minDate={minDate}
          maxDate={maxDate}
          customInput={
            <TextField
              {...rest}
              name={name}
              onBlur={onBlur}
              required={required}
              error={invalid}
              helperText={error ? error.message : rest.helperText}
              inputRef={ref}
            />
          }
        />
      )}
    />
  );
};
export default memo(DateRangePicker);
