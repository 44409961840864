import React, { memo } from "react";
import { Typography } from "@mui/material";
import GridItem from "../shared/GridItem";
import GridPrime from "../shared/GridPrime";

const ImgInfo = ({ src, titleName, children }) => (
  <GridItem xs={12} lg={4}>
    <GridPrime wrap="nowrap">
      <GridItem>
        <img alt="img" src={src} />
      </GridItem>
      <GridItem>
        <Typography variant="h5">{titleName}</Typography>
      </GridItem>
    </GridPrime>
    {children}
  </GridItem>
);

export default memo(ImgInfo);
