export const a5 = "4px";
export const a8 = "8px";
export const b1 = "12px";
export const b2 = "14px";
export const b3 = "16px";
export const b4 = "21px";
export const b5 = "23px";
export const b6 = "35px";
export const b7 = 45;
export const b8 = 64;
export const c2 = "200px";
export const c4 = "320px";
export const c7 = "500px";
export const drawerWidth = 280;
export const sizeBreak = 1200;
