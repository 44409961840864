import React, { memo, useState } from "react";
import { Button, Avatar, IconButton, Box } from "@mui/material";
import GridItem from "../shared/GridItem";
import { a5, b2, b7 } from "../../theme/metrics";
import { deleteInvitee, deliverMessage } from "../../api/strapi";
import { useAppContext } from "../../router/AppContext";
import LoadingPicture from "../shared/LoadingPicture";
import { MoreVert } from "@mui/icons-material";
import GridPrime from "../shared/GridPrime";
import GridCol from "../shared/GridCol";
import { useLocalStorage } from "../../api/useLocalStorage";

const showResponded = (myValue) => {
  if (myValue === "accepted") {
    return "";
  } else if (myValue === "rejected") {
    return "Rejected";
  } else {
    return "Not responded";
  }
};

const InviteInfo = ({ myId, myAtttributes, selectedId, setSelectedId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData] = useLocalStorage("userData", null);
  const { setIsError, setSnackMessage } = useAppContext();

  return (
    <GridItem>
      <GridPrime justifyContent="space-between" wrap="nowrap">
        <GridItem>
          <GridPrime wrap="nowrap">
            <GridItem>
              <Avatar
                sx={{
                  width: b7,
                  height: b7,
                  border:
                    myAtttributes.responded === "pending"
                      ? `${a5} solid`
                      : undefined,
                  borderColor: "primary.main",
                }}
                alt={myAtttributes.name}
              />
            </GridItem>
            <GridItem>
              <Box>
                {myAtttributes.name != null
                  ? myAtttributes.name
                  : myAtttributes.email != null
                  ? myAtttributes.email.replace("@", "@​")
                  : ""}
              </Box>
              <Box sx={{ fontSize: b2 }}>
                {showResponded(myAtttributes.responded)}
              </Box>
            </GridItem>
          </GridPrime>
        </GridItem>
        <GridItem>
          {!isLoading && (
            <>
              {selectedId === myId && (
                <GridCol>
                  <GridItem>
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      onClick={async () => {
                        // eslint-disable-next-line no-restricted-globals
                        const isDelete = confirm(
                          "Are you sure you want to remove invitee?"
                        );
                        if (!isDelete) {
                          return;
                        }

                        setIsLoading(true);
                        try {
                          await deleteInvitee(myId);
                          // eslint-disable-next-line no-restricted-globals
                          location.reload();
                        } catch {
                          setIsError(true);
                        } finally {
                          setIsLoading(false);
                        }
                      }}
                    >
                      Remove
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      onClick={async () => {
                        setIsLoading(true);
                        try {
                          await deliverMessage({
                            hostValue: userData.user,
                            eventTitle:
                              myAtttributes.event.data.attributes.title,
                            eventId: myAtttributes.event.data.id,
                            inviteeInfo: myAtttributes,
                          });
                          setSnackMessage("Invitation sent!");
                        } catch {
                          setIsError(true);
                        } finally {
                          setIsLoading(false);
                        }
                      }}
                    >
                      Resend Invite
                    </Button>
                  </GridItem>
                </GridCol>
              )}
              {selectedId !== myId && (
                <IconButton onClick={() => setSelectedId(myId)}>
                  <MoreVert />
                </IconButton>
              )}
            </>
          )}
          {isLoading && <LoadingPicture />}
        </GridItem>
      </GridPrime>
    </GridItem>
  );
};

export default memo(InviteInfo);
