import React, { memo } from "react";
import { SwitchElement } from "react-hook-form-mui";

const SwitchBool = ({ name, label, ...myProps }) => {
  return (
    <SwitchElement
      label={label}
      name={name}
      labelPlacement="start"
      sx={{
        width: "100%",
        marginLeft: 0,
        marginRight: 0,
        "&>.MuiFormControlLabel-label": {
          flexGrow: 1,
        },
      }}
      {...myProps}
    />
  );
};

export default memo(SwitchBool);
