import React, { memo } from "react";
import { Button } from "@mui/material";
import GridItem from "../shared/GridItem";
import LoadingPicture from "../shared/LoadingPicture";
import GridPrime from "../shared/GridPrime";

const ResponseButtons = ({
  handleAccept,
  handleDecline,
  isLoading,
  isResponse,
}) => {
  return (
    <>
      {isResponse && (
        <GridItem sx={{ textAlign: "center" }}>
          {!isLoading && (
            <GridPrime justifyContent="center">
              <GridItem>
                <Button variant="contained" size="large" onClick={handleAccept}>
                  Accept
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  variant="outlined"
                  color="error"
                  size="large"
                  onClick={handleDecline}
                >
                  Decline
                </Button>
              </GridItem>
            </GridPrime>
          )}
          {isLoading && <LoadingPicture />}
        </GridItem>
      )}
    </>
  );
};

export default memo(ResponseButtons);
