import { createTheme } from "@mui/material/styles";
import {
  firstGreenLight,
  firstGreenDark,
  secondGreenLight,
  secondGreenDark,
} from "./colors";
import { a8 } from "./metrics";

const material = (mode) => {
  const myPallete =
    mode === "dark"
      ? {
          primary: {
            main: firstGreenDark,
            contrastText: "white",
          },
          secondary: {
            main: secondGreenDark,
          },
        }
      : {
          primary: {
            main: firstGreenLight,
            contrastText: "white",
          },
          secondary: {
            main: secondGreenLight,
          },
        };
  return createTheme({
    typography: {
      fontFamily: `Poppins, sans-serif`,
      button: {
        textTransform: "none",
      },
      h5: {
        marginTop: a8,
        marginBottom: a8,
        fontWeight: "bold",
      },
    },
    shape: {
      // https://stackoverflow.com/a/57489080/6227407
      borderRadius: 9,
    },
    palette: {
      mode,
      ...myPallete,
      // success
      // info
    },
  });
};

export default material;
