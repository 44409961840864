import React, { memo, useEffect, useRef } from "react";
import { Grid } from "@mui/material";

const GridItem = ({ children, ...myProps }) => {
  const myRef = useRef(null);

  useEffect(() => {
    if (myRef.current == null) {
      return;
    }

    let myCurrent = {};
    myCurrent = myRef.current;
    if (!myCurrent.parentElement.classList.contains("MuiGrid-container")) {
      throw new Error("parentElement is not Grid contained");
    }
  }, []);

  return (
    <Grid item ref={myRef} {...myProps}>
      {children}
    </Grid>
  );
};

export default memo(GridItem);
