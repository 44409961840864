import React, { memo, useCallback, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import GridItem from "../shared/GridItem";
import { b3, sizeBreak } from "../../theme/metrics";
import { secondGreenLight } from "../../theme/colors";
import _ from "lodash";
import GridPrime from "../shared/GridPrime";
import GridCol from "../shared/GridCol";

const showTime = 300;

const handleGoogle = () => {
  window.location.replace(
    `${process.env.REACT_APP_BASE_URL}api/connect/google`
  );
};

const LandingGrid = ({ sx, styleExtra }) => {
  const [imageSize, setImageSize] = useState("100px");

  const resizeBase = () => {
    let imgSize = window.innerWidth > sizeBreak ? "100%" : "70%";

    const marginTotal = 117;
    const plusRatio = 1.8;
    if (window.innerWidth / window.innerHeight > plusRatio) {
      imgSize = `${window.innerHeight - marginTotal}px`;
    }
    setImageSize(imgSize);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(_.debounce(resizeBase, showTime), []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GridPrime spacing={3} justifyContent="center" sx={sx}>
      <GridItem
        md={6}
        style={{
          textAlign: "center",
        }}
      >
        <img
          alt="img"
          src="/login4.png"
          style={{
            borderRadius: b3,
            backgroundColor: secondGreenLight,
            width: imageSize,
            transitionDuration: "1s",
          }}
        />
      </GridItem>
      <GridItem md={6}>
        <GridCol spacing={3} sx={styleExtra}>
          <GridItem>
            <Typography variant="h5" sx={{ color: "gray" }}>
              Thanks for joining our BETA
            </Typography>
          </GridItem>
          <GridItem>
            <Typography variant="h4">
              Start planning with{" "}
              <img
                alt="img"
                src="/gatsbylogo4.svg"
                style={{ marginBottom: "-11px" }}
              />{" "}
              today!
            </Typography>
          </GridItem>
          <GridItem
            sx={{
              "&>input:hover": { filter: "brightness(80%)" },
              "&>input:active": { opacity: 0.5 },
            }}
          >
            <input
              type="image"
              src="/googleSign4.png"
              alt="img"
              style={{ width: "300px" }}
              onClick={handleGoogle}
            />
          </GridItem>
        </GridCol>
      </GridItem>
    </GridPrime>
  );
};

export default memo(LandingGrid);
